import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';
export const dev: IPartnerAppSettings = {
  cdnBaseUrl: 'http://localhost:5003',
  apiBaseUrl: 'http://localhost:3000',
  apimUrl: 'http://localhost:3000',
  apimSub: 'not needed',
  environment: 'dev',
  compassOneBaseUrl: 'https://c1int.azurewebsites.net',
  mdmBaseUrl: 'https://servicesint.azurewebsites.net/#/contracts',
  serviceEndPoints: [
    {
      clientId: '136b9b86-4fa2-476a-9ea5-ffbdbe4f87bc',
      serviceEndPoint: 'http://localhost:3000/api',
    },
  ]
};
