/*
This is the environment that is deployed to Production. (This is always a gated release and must be approved manually before deployment happens.
This is always done on the master branch.)
This points to data at MSP and has CDN blob storage on the cloud.
This is the configuration our users in production will be working with.
*/
import { IPjMAppSettings } from "../interface/IPjMAppSettings";

export const prod: IPjMAppSettings = {
    delegationTenant: "ES", // this is from fxp
    cdnBaseUrl: "https://PJMUIPROD-dme6d6bhhvcpdvf5.b01.azurefd.net/pjmux-angular",
    serviceEndPoints: [
        {
            clientId: "be983076-c700-4d52-aa05-874355076224",
            serviceEndPoint: "https://professionalservices.microsoft.com/lmsprod/"
        },
        {
            clientId: "83902542-ffa9-4272-9d09-31b93eccabfa",
            serviceEndPoint: "https://projectservices-api-prod.z01.azurefd.net/api/"
        },
        {
            clientId: "2092fb63-2773-45c9-9067-dc001542f916",
            serviceEndPoint: "https://professionalservices.microsoft.com/pomgmt/api/v1"
        },
        {
            serviceEndPoint: "https://projectservices-api-prod.z01.azurefd.net/functions/api",
            clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
        },
        {
            clientId: "https://microsoft.onmicrosoft.com/wwtkulsprod",
            serviceEndPoint: "https://servicestkapipack.microsoft.com/api/v2/approval/laborentries/"
        },
        {
            clientId: "72a9e05a-2c3c-47ae-bbb2-06ec753f307a",
            serviceEndPoint: "https://esxp-prod.azurefd.net/grm/rm/api"
        },
        {
            clientId: "72a9e05a-2c3c-47ae-bbb2-06ec753f307a",
            serviceEndPoint: "https://faultcontrolapi.trafficmanager.net/"
        },
        {
            clientId: "http://VirtuosoWorldwideApi/",
          serviceEndPoint: "https://api.virtuoso.microsoft.com/core/v1/"
        },
        {
            clientId: "f2aef026-7330-461d-92e8-29062fe022f2",
            serviceEndPoint: "https://professionalservices.microsoft.com/expensestore/api"
        },
        {
            clientId: "00000002-0000-0000-c000-000000000000",
            serviceEndPoint: "https://graph.windows.net"
        },
        {
            clientId: "c6f3bad5-217f-4c88-b648-4ee3c02cd0ab",
            serviceEndPoint: "https://professionalservices.microsoft.com/o2c/som/api"
        },
        {
            clientId: "e732df60-9820-4895-acf7-692fd9aef75f",
            serviceEndPoint: "https://campusapi.microsoft.com/"
        },
        {
            clientId: "fa0e324f-8c1b-436d-8f8a-b9f07c44a2bc",
            serviceEndPoint: "https://sapbobjblp-microsoft.msappproxy.net/BOE/OpenDocument/"
        },
        {
            clientId: "cc1b9d80-0cfd-42dc-aea1-c610b6393ab9",
            serviceEndPoint: "https://delegation-api-prod.azurefd.net/api/delegations/"
        },
        {
            clientId: "cc1b9d80-0cfd-42dc-aea1-c610b6393ab9",
            serviceEndPoint: "https://flighting-api-prod.azurefd.net"
        },
        {
            clientId: "e03cd7c9-8c94-45dd-b4b8-68957267ec20",
            serviceEndPoint: "https://pslakeds.azurewebsites.net/pjm/api"
        },
        {
            serviceEndPoint: "https://esxp.microsoft.com/rmavl/api",
            clientId: "bc72c2f2-9689-42a0-aa49-f579509c867b"
        },
        {
            serviceEndPoint: "https://oneprofileprodcoreapi.microsoft.com",
            clientId: "6db0b12a-edd4-4521-8406-a615ce8659d6"
        },
        {
            serviceEndPoint: "https://forecastservice-prod.azurewebsites.net/",
            clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
        }
    ],
    featureFlags: [
        {
            name: "MisalignedAmendments",
            enabled: true
        },
        {
            name: "Pubsec",
            enabled: false
        },
        {
            name: "ShowLastUpdatedColumn",
            enabled: false
        },
        {
            name: "ShowUSPubSec",
            enabled: true
        },
        {
            name: "ShowExpenseReportsInKeyActions",
            enabled: false
        },
        {
            name: "ShowClinSlinFunding",
            enabled: true
        },
        {
            name: "DisableAlertsEmailNotifications",
            enabled: true
        },
        {
            name: "hidePinnedEntitiesInPortfolio",
            enabled: true
        },
        {
            name: "showFinancialsTabPortfolio",
            enabled: true
        },
        {
            name: "enableResourceScheduleFlow",
            enabled: true
        },
        {
            name: "enableFcrResourceLocationAndRoleEdit",
            enabled: false
        },
        {
            name: "showMSFederalEngagements",
            enabled: true
        },
        {
            name: "enableEngagementMinDateValidation",
            enabled: false
        },
        {
            name: "useNewPoEndPoint",
            enabled: false
        },
        {
            name: "enableFinancialsExport",
            enabled: false
        },
        {
            name: "enableBulkMaintenance",
            enabled: true
        },
        {
            name: "showOPLevel2Skills",
            enabled: true
        },
        {
            name: "deleteCSATContact",
            enabled: true
        },
        {
            name: "ecifIoExtension",
            enabled: true
        },
        {
            name: "enableMXDRChanges",
            enabled: true
        },
        {
            name: "enableComplexUnitsFeature",
            enabled: true
        },
        {
            name: "enableProjectClosureFeature",
            enabled: true
        }
    ],
    partnerEndpoints: [
        {
            Endpoint: "https://professionalservices.microsoft.com/lmsprod/api/v1.1/",
            ResourceId: "be983076-c700-4d52-aa05-874355076224"
        },
        {
            Endpoint: "https://projectservices-api-prod.z01.azurefd.net/api/",
            ResourceId: "83902542-ffa9-4272-9d09-31b93eccabfa"
        },
        {
            Endpoint: "https://projectservices-api-prod.z01.azurefd.net/functions/api",
            ResourceId: "83902542-ffa9-4272-9d09-31b93eccabfa"
        },
        {
            Endpoint: "https://professionalservices.microsoft.com/pomgmt/api/v1",
            ResourceId: "2092fb63-2773-45c9-9067-dc001542f916"
        },
        {
            Endpoint: "https://servicestkapipack.microsoft.com/api/v2/approval/laborentries/",
            ResourceId: "https://microsoft.onmicrosoft.com/wwtkulsprod"
        },
        {
            Endpoint: "https://esxp-prod.azurefd.net/grm/rm/api",
            ResourceId: "72a9e05a-2c3c-47ae-bbb2-06ec753f307a"
        },
        {
            Endpoint: "https://faultcontrolapi.trafficmanager.net/",
            ResourceId: "72a9e05a-2c3c-47ae-bbb2-06ec753f307a"
        },
        {
          Endpoint: "https://api.virtuoso.microsoft.com/core/v1/",
            ResourceId: "http://VirtuosoWorldwideApi/"
        },
        {
            Endpoint: "https://professionalservices.microsoft.com/expensestore/api",
            ResourceId: "f2aef026-7330-461d-92e8-29062fe022f2"
        },
        {
            Endpoint: "https://graph.windows.net",
            ResourceId: "00000002-0000-0000-c000-000000000000"
        },
        {
            Endpoint: "https://professionalservices.microsoft.com/o2c/som/api",
            ResourceId: "c6f3bad5-217f-4c88-b648-4ee3c02cd0ab"
        },
        {
            Endpoint: "https://campusapi.microsoft.com/",
            ResourceId: "e732df60-9820-4895-acf7-692fd9aef75f"
        },
        {
            Endpoint: "https://sapbobjblp-microsoft.msappproxy.net/BOE/OpenDocument/",
            ResourceId: "fa0e324f-8c1b-436d-8f8a-b9f07c44a2bc"
        },
        {
            Endpoint: "https://delegation-api-prod.azurefd.net/api/delegations/",
            ResourceId: "cc1b9d80-0cfd-42dc-aea1-c610b6393ab9"
        },
        {
            Endpoint: "https://esxp.microsoft.com/rmavl/api",
            ResourceId: "bc72c2f2-9689-42a0-aa49-f579509c867b"
        },
        {
            Endpoint: "https://forecastservice-prod.azurewebsites.net/",
            ResourceId: "83902542-ffa9-4272-9d09-31b93eccabfa"
        },
    ],
};
