import { IRootScope } from "../interfaces/IRootScope";
import { UserProfileService } from "../services/userProfileService";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { TelemetryContext } from "../telemetry/telemetrycontext";
import { UserInfoService } from "../services/UserInfoService";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { CustomEvents, FxpConstants, PerfMarkers, ApplicationConstants } from "../common/ApplicationConstants";
import { GlobalExceptionHandler } from "../telemetry/GlobalExceptionHandler";
import { CommonUtils } from "../utils/CommonUtils";
import { PlannedDownTimeService } from "../../app/systemMessages/PlannedDownTimeService";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpContext } from "../context/FxpContext";
import { SettingsType } from "../common/SettingsType";
import { ISettingsService } from "../interfaces/ISettingsService";
import { FeatureFlagService } from "@fxp/flightingsdk";
import { UserClaimsService } from "../services/UserClaimsService";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { showSystemDownOverlay } from "../utils/systemDownOverlay";
import { FxpModalConstant, FxpCancellableModalConstant } from "../constants/FxpModal.constants";
import { DashboardService } from "../services/dashboardService";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ComponentType } from "@microsoftit/telemetry-extensions-npm";
import { SystemEvent } from "../telemetry/SystemEvent";
import { ILogger } from "../interfaces/ILogger";
import { HideLoader } from "../../app/loader/loader.actions";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { ErrorCodes } from "../constants/errorCodes";
import { FxpBootstrap } from "../boot/fxpboot";
import { MsalAuthenticationService } from "../services/MsalAuthenticationService";
import * as Q from 'q';

import { FxpRootScopeService, IRootSubjects } from "../services/FxpRootScopeService";
import { FxpUIBModalService } from "../services/FxpUIBModalService";

/**
 * @application  Fxp
 */
/**
 * A main factory which acts as an helper for AppController. This is the factory having common functionalities.
 * @class Fxp.Factory.AppControllerHelper
 * @classdesc An helper factory for AppController in FxPApp module
 * @example <caption>
 *  //How To use this factory
 *  angular.module('FxPApp').controller('AppController', ['AppControllerHelper', AppControllerHelper]);
 *  function AppController(AnyDependency){ AppControllerHelper.getBasicProfile(); }
 */

declare var _: any;

export class AppControllerHelper {
  private static _instance: AppControllerHelper;
  private $rootScope: IRootScope;
  private rootScopeService: FxpRootScopeService;
  private fxpRootScope: IRootSubjects;
  private userProfileService: UserProfileService;
  private fxpTelemetryContext: TelemetryContext;
  private fxpLoggerService: ILogger;
  private userInfoService: UserInfoService;
  private fxpConstants: FxpConstants;
  private fxpConfiguration: FxpConfigurationService;
  private deviceFactory: any;
  private deviceDetector: any;
  private userClaimsService: UserClaimsService;
  private claimsCache: any;
  private profileCache: any;
  private pageTourWaitTime: number = 2000;
  private userThumbnailWaitTime: number = 3000;
  private dashboardService: DashboardService;
  private readonly sourceForTelemetry: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AppControllerHelper`;

  constructor($rootScope: IRootScope,
    userProfileService: UserProfileService, private fxpGlobalStoreService: FxpGlobalStoreService, fxpTelemetryContext: TelemetryContext,
    fxpLoggerService: ILogger, userInfoService: UserInfoService, private featureFlagService: FeatureFlagService, private startUpFlightConfig: any, private settingsService: ISettingsService, private fxpContext: FxpContext, private fxpMessage: FxpMessageService, deviceFactory: any, deviceDetector: any,
    private plannedDownTimeService: PlannedDownTimeService, fxpConfiguration: FxpConfigurationService, userClaimsService: UserClaimsService,
    private modalSerivce: FxpUIBModalService,
    private FxpModalConstant: FxpModalConstant,
    dashboardService: DashboardService,
    private msalAuthenticationService: MsalAuthenticationService
  ) {
    this.$rootScope = $rootScope;
    this.rootScopeService = FxpRootScopeService.getInstance();
    this.rootScopeService.rootScopeSubject.subscribe((data) => {
      this.fxpRootScope = data;
    });
    this.userProfileService = userProfileService;
    this.fxpTelemetryContext = fxpTelemetryContext;
    this.fxpLoggerService = fxpLoggerService;
    this.userInfoService = userInfoService;
    this.fxpConstants = FxpConstants;
    this.fxpConfiguration = fxpConfiguration;
    this.$rootScope.isFxpLoadedWithClaims = false;
    this.deviceFactory = deviceFactory;
    this.deviceDetector = deviceDetector;
    this.userClaimsService = userClaimsService;
    this.dashboardService = dashboardService;

    if (AppControllerHelper._instance) {
      return AppControllerHelper._instance;
    }
    AppControllerHelper._instance = this;
    this.handleServiceWorkerUpdateEvent();
  }

  private handleServiceWorkerUpdateEvent() {
    const self = this;
    this.$rootScope.$on("ServiceWorkerUpdated", function () {
      self.showReloadModalWithCancel("Application Updated", "Please click refresh to update to a new version of this application.", "Refresh");
    });
  }

  private getLoggedInUserOID(): string {
    var aadObjectId = this.msalAuthenticationService.getAccount();
    this.setLoggedInUserOID(aadObjectId.localAccountId);
    return aadObjectId.localAccountId;
  }

  private setLoggedInUserOID(aadObjectId: string): void {
    this.userInfoService.setLoggedInUserOID(aadObjectId);
    if (!this.userProfileService.isObo()) {
      this.userInfoService.setCurrentUserOID(aadObjectId);

    }
  }
  /**
   * A method to get the basic profile.
   * @method Fxp.Factory.AppControllerHelper.getBasicProfile($scope, true)
   * @param {angular.IScope} $scope $scope from angular app view.
   * @param {Boolean} isRefresh A boolean which if false gets the data from fxpContext(global cache).
   * @example <caption> Example to use getBasicProfile</caption>
   *  AppControllerHelper.getBasicProfile($scopem true);
   */
  getBasicProfile($scope: any): void {
    var upn = this.msalAuthenticationService.getAccount().username;
    var aadOID = this.getLoggedInUserOID();
    var self = this;
    var isAppLaunched = false;

    var fxpAppLaunched = window.sessionStorage.getItem(ApplicationConstants.FxpApplaunchedKey)

    isAppLaunched = (!CommonUtils.isNullOrEmpty(fxpAppLaunched) && fxpAppLaunched === "true");


    self.userInfoService.setLoggedInUserUpn(upn);

    self.fxpTelemetryContext.addToGlobalPropertyBag('LoggedInUser', upn);
    var profileCacheDefer = Q.defer();
    self.userProfileService.getBasicProfile(aadOID, isAppLaunched).then(
      (profile) => {
        self.handleProfileServiceResponse(profile, (cachedData, apiResponse) => {
          let result = cachedData && (cachedData.RoleGroupID != apiResponse.RoleGroupID || cachedData.TenantKey != apiResponse.TenantKey);
          if (result) {
            localStorage["loggedInUserInfo-Old"] = JSON.stringify(cachedData);
          }

          self.$rootScope.isUserInfoAvailable = true;
          return result;
        });
        profileCacheDefer.resolve(profile);
      },
      err => {
        self.$rootScope.roleGroupId = -1;
        self.fxpGlobalStoreService.DispatchGlobalAction("Platform", HideLoader({}));
        self.checkOneProfileAndCalimStatus(err);
        self.$rootScope.isFxpLoadedWithClaims = false;

        let currentTime = new Date().toLocaleString();
        console.log(`AppControllerHelper:BasicProfile Error Time- ${currentTime}`);
        let propbag = this.fxpLoggerService.createPropertyBag();
        propbag.addToBag('Time', `${currentTime}`);
        this.fxpLoggerService.logException(this.sourceForTelemetry, err, propbag, null, null, ErrorSeverityLevel.High, ErrorCodes.ProfileServiceFailure);

      },
      cachedProfile => {
        self.handleProfileServiceResponse(cachedProfile);
        profileCacheDefer.resolve(cachedProfile);
        self.$rootScope.isUserInfoAvailable = true;

      }
    );

    self.userClaimsService.getCalimsSvc(aadOID).then(
      function (claims) {
        self.handleClaimServiceResponse(claims, (cachedData, apiResponse) => {
          if (!cachedData)
            return false;

          let cachedRoles = self.getRoles(cachedData);
          let apiRoles = self.getRoles(apiResponse);

          let cachedResources = self.getResources(cachedData);
          let apiResources = self.getResources(apiResponse);

          let hasRolesChanged = JSON.stringify(CommonUtils.sortJsonObj(apiRoles)) !== JSON.stringify(CommonUtils.sortJsonObj(cachedRoles));
          let hasResourcesChanged = JSON.stringify(CommonUtils.sortJsonObj(apiResources)) !== JSON.stringify(CommonUtils.sortJsonObj(cachedResources));

          let hasClaimsChanged = hasRolesChanged || hasResourcesChanged;
          if (hasClaimsChanged) {
            localStorage["loggedInUserClaims-Old"] = JSON.stringify(cachedData);

            // The below telemetry will be removed once the patch is stabilized
            let changeSummary = self.fxpLoggerService.createPropertyBag();
            changeSummary.addToBag("HasRolesChanged", hasRolesChanged + "");
            changeSummary.addToBag("HasResourcesChanged", hasResourcesChanged + "");
            changeSummary.addToBag("CachedRoles", JSON.stringify(CommonUtils.sortJsonObj(cachedRoles)));
            changeSummary.addToBag("UpdatedServerRoles", JSON.stringify(CommonUtils.sortJsonObj(apiRoles)));
            changeSummary.addToBag("CachedResources", JSON.stringify(CommonUtils.sortJsonObj(cachedResources)));
            changeSummary.addToBag("UpdatedServerResources", JSON.stringify(CommonUtils.sortJsonObj(apiResources)));
            self.fxpLoggerService.logEvent(self.sourceForTelemetry, "UserClaims.Updated", changeSummary);
          }

          return hasClaimsChanged;
        });
      },
      err => {
        let bag = self.fxpLoggerService.createPropertyBag();
        bag.addToBag('Error', CommonUtils.objectToString(err));
        self.$rootScope.roleGroupId = -1;
        self.fxpGlobalStoreService.DispatchGlobalAction("Platform", HideLoader({}));
        self.checkOneProfileAndCalimStatus(err);
        self.$rootScope.isFxpLoadedWithClaims = false;
        this.fxpLoggerService.logException(this.sourceForTelemetry, err, bag, null, null, ErrorSeverityLevel.High, ErrorCodes.AuthService_GetClaimsFailure);
      },
      cachedClaims => {
        self.handleClaimServiceResponse(cachedClaims);
      }
    );

    let getUserThumbnailTimeoutHandle = setTimeout(() => {
      self.fxpLoggerService.startTrackPerformance(PerfMarkers.GetUserThumbnail);
      this.userProfileService.getUserThumbnail(upn, false).then(
        function (data) {
          var blob = new Blob([data?.data], { type: 'image/jpeg' });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader?.result;
            self.rootScopeService.setRootScopeField("userThumbnailPhoto", base64data);
            self.fxpLoggerService.stopTrackPerformance(PerfMarkers.GetUserThumbnail);
          };
          reader.onerror = function (error) {
            self.rootScopeService.setRootScopeField("userThumbnailPhoto", "/assets/pictures/User.png");
            self.fxpLoggerService.stopTrackPerformance(PerfMarkers.GetUserThumbnail);
          }
        }, function (data) {
          self.rootScopeService.setRootScopeField("userThumbnailPhoto", "/assets/pictures/User.png");
          self.fxpLoggerService.stopTrackPerformance(PerfMarkers.GetUserThumbnail);
        }
      );
      clearTimeout(getUserThumbnailTimeoutHandle);
    }, self.userThumbnailWaitTime);

    let getReportsToThumbnailTimeoutHandle = setTimeout(() => {
      self.fxpLoggerService.startTrackPerformance(PerfMarkers.GetUserThumbnail);
      var currentProfile = self.fxpRootScope.userProfile;
      if (currentProfile) {
        var reportsToAlias: string = currentProfile.reportsTo;
        var reportsToUpn: string = reportsToAlias + '@microsoft.com';
        this.userProfileService.getUserThumbnail(reportsToUpn, false).then(
          function (data) {
            var blob = new Blob([data?.data], { type: 'image/jpeg' });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader?.result;
              self.rootScopeService.setRootScopeField("reportsToThumbnailPhoto", base64data);
              self.fxpLoggerService.stopTrackPerformance(PerfMarkers.GetUserThumbnail);
            };
            reader.onerror = function (error) {
              self.rootScopeService.setRootScopeField("reportsToThumbnailPhoto", "/assets/pictures/User.png");
              self.fxpLoggerService.stopTrackPerformance(PerfMarkers.GetUserThumbnail);
            }
          },
          function (data) {
            self.rootScopeService.setRootScopeField("reportsToThumbnailPhoto", "/assets/pictures/User.png");
            self.fxpLoggerService.stopTrackPerformance(PerfMarkers.GetUserThumbnail);
          }
        );
      }
      else {
        self.rootScopeService.setRootScopeField("reportsToThumbnailPhoto", "/assets/pictures/User.png");
        self.fxpLoggerService.stopTrackPerformance(PerfMarkers.GetUserThumbnail);
      }

      clearTimeout(getReportsToThumbnailTimeoutHandle);
    }, self.userThumbnailWaitTime
    );
  }

  blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error("Failed to convert blob to data URL"));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private onClaimsSuccess() {
    var self = this;
    var fxpLoggerService = this.fxpLoggerService;
    self.$rootScope.isFxpLoadedWithClaims = true;
    self.$rootScope.roleGroupId = self.$rootScope.roleGroupIdInternal || -1;
    self.setDefaultAppRole();
    fxpLoggerService.stopTrackPerformance(PerfMarkers.PreDashboardLoad);
    fxpLoggerService.startTrackPerformance(PerfMarkers.DashboardLoad);
    self.$rootScope.$broadcast(FxpBroadcastedEvents.OnFxpLoadCompleted);
    window.sessionStorage.setItem(ApplicationConstants.FxpApplaunchedKey, "true");

    fxpLoggerService.logSystemEvent(self.sourceForTelemetry,
      new SystemEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ClaimsSuccess`,
        ComponentType.Web,
        "Claims call successful.")
    );
  }

  private getRoles(claims) {
    let roles = [];
    if (claims === undefined || claims === null || claims.tenantClaims === undefined || claims.tenantClaims === null) {
      return roles;
    }

    for (let npd in claims.tenantClaims) {
      if (claims.tenantClaims[npd].claims !== undefined
        && claims.tenantClaims[npd].claims !== null) {
        _.each(claims.tenantClaims[npd].claims.roles, function (role) {
          roles.push(role);
        });
      }

      if (claims.tenantClaims[npd].delegatedClaims !== undefined
        && claims.tenantClaims[npd].delegatedClaims !== null) {
        _.each(claims.tenantClaims[npd].delegatedClaims.roles, function (role) {
          roles.push(role);
        });
      }
    }
    return roles;
  }

  private getResources(claims) {
    let resources = [];
    if (claims === undefined || claims === null || claims.tenantClaims === undefined || claims.tenantClaims === null) {
      return resources;
    }

    for (let npd in claims.tenantClaims) {
      if (claims.tenantClaims[npd].claims !== undefined
        && claims.tenantClaims[npd].claims !== null) {
        _.each(claims.tenantClaims[npd].claims.resourcePermissions, function (resourcePermissions) {
          resources.push(resourcePermissions);
        });
      }

      if (claims.tenantClaims[npd].delegatedClaims !== undefined
        && claims.tenantClaims[npd].delegatedClaims !== null) {
        _.each(claims.tenantClaims[npd].delegatedClaims.roles, function (resourcePermissions) {
          resources.push(resourcePermissions);
        });
      }
    }
    return resources;
  }

  private handleProfileServiceResponse(data: any, hasProfileCacheChanged: any = () => false) {
    const self = this;
    self.rootScopeService.setRootScopeField("userProfile", data);
    if (data) {
      if (hasProfileCacheChanged.bind(self)(self.profileCache, data)) {
        this.showReloadModal("Profile Updated", "It looks like your profile was updated since you logged in last time, Reload to get the latest changes");
      } else {
        self.profileCache = data;
        try {
          let Id = data.roleGroupId == undefined || data.roleGroupId == null || data.roleGroupId == 0 ? -1 : data.roleGroupId;
          self.$rootScope.roleGroupIdInternal = parseInt(Id);
          self.fxpLoggerService.setLoggedInUserContext(data.roleGroupId, data.roleGroupName, self.userProfileService.isObo(), data.TenantKey, data.TenantName);
        } catch (e) {
          self.$rootScope.roleGroupIdInternal = -1;
          self.fxpLoggerService.logException(`${self.sourceForTelemetry}.HandleProfileServiceResponse`, e, null, null, null, ErrorSeverityLevel.Medium, ErrorCodes.HandleProfileServiceResponse);
        }
        //Setting Tenant Name to the rootScope
        self.$rootScope.tenantId = data.TenantName ? data.TenantName : "-1";
      }
    } else {
      self.$rootScope.roleGroupId = -1;
    }
  }

  private handleClaimServiceResponse(data: any, hasCacheChanged: any = () => false) {
    const self = this;
    if (data) {
      if (hasCacheChanged.bind(self)(self.claimsCache, data)) {
        this.showReloadModal("Application Permission Updated", "It looks like your permissions were updated since you logged in last time, Reload to get the latest changes");
      } else {
        self.claimsCache = data;
        let appRole = data.defaultAppRole || "";
        if (appRole == "") {
          this.showNoAppRoleErrorMessage.bind(self)();
        }
        self.fxpTelemetryContext.setUserRole(appRole);
        self.$rootScope.defaultAppRoleInternal = appRole;
        self.onClaimsSuccess();
      }
    } else {
      self.$rootScope.defaultAppRole = "";
    }

  }

  private showReloadModal(title: string, description: string, buttonText: string = "Reload") {
    const modalProps: any = FxpModalConstant.options;
    const self = this;
    modalProps.controller = function () {
      this.Title = title;
      this.Content = description;
      this.ButtonText = buttonText;
      this.onClick = _ => {
        self.fxpLoggerService.logEvent(`${self.sourceForTelemetry}.ShowReloadModal`, `User clicked on reload ${title}`);
        document.location.reload();
      };
    };

    this.modalSerivce.open(modalProps);
  }

  private showReloadModalWithCancel(title: string, description: string, okButtonText: string = "Reload") {
    const modalProps: any = FxpCancellableModalConstant.options;
    const self = this;
    const telemetry_source = `${self.sourceForTelemetry}.ShowReloadModalWithCancel`;
    modalProps.controller = function ($scope) {
      this.Title = title;
      this.Content = description;
      this.ButtonText = okButtonText;
      this.onClick = _ => {
        self.fxpLoggerService.logEvent(telemetry_source, `User clicked on reload ${title}`);
        document.location.reload();
      };
      this.onCancel = _ => {
        self.fxpLoggerService.logEvent(telemetry_source, `User clicked on cancel ${title}`);
        self.modalSerivce.dismiss();
      };
    };

    this.modalSerivce.open(modalProps);
  }
  public showModalPopupWindow(title: string, description: string, buttonText: string, callback: any) {
    const modalProps: any = FxpModalConstant.options;
    modalProps.controller = function () {
      this.Title = title;
      this.Content = description;
      this.ButtonText = buttonText;
      this.onClick = _ => {
        callback();
      };
    };

    this.modalSerivce.open(modalProps);
  }

  private showNoAppRoleErrorMessage() {
    let self = this;
    var headerText = CommonUtils.getTenantURLSpecificPageTitle(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.InvalidRoleGroupID.ErrorMessageTitle);
    var descriptionText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.InvalidRoleGroupID.ErrorMessage;
    var hyperlinkText = CommonUtils.getTenantURLSpecificPageTitle(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.InvalidRoleGroupID.HyperlinkText);
    var hyperlinkUrl = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.InvalidRoleGroupID.HyperlinkUrl;
    var pageTitle = CommonUtils.getTenantURLSpecificPageTitle(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.InvalidRoleGroupID.PagetTitle);
    showSystemDownOverlay(headerText, descriptionText, pageTitle, hyperlinkText, hyperlinkUrl, true);
  }

  /**
   * A method to use show the SystemDown/Profile error, if OneProfileAndCalims API falied.
   * @method Fxp.Controllers.AppController.checkOneProfileAndCalimStatus
   * @param {object} error A error Object need to send
   * @example <caption> Example to use checkOneProfileAndCalimStatus</caption>
   * AppControllerHelper.checkOneProfileAndCalimStatus(error)
   */
  checkOneProfileAndCalimStatus(err: any) {
    var self = this, headerText, descriptionText, pageTitle,
      profileStore = window["tenantConfiguration"].ProfileStore || {},
      authStore = window["tenantConfiguration"].AuthStore || {};
    var propBag = self.fxpLoggerService.createPropertyBag();
    propBag.addToBag("UserName", self.userInfoService.getCurrentUserUpn());

    if (err && err.config) {
      propBag.addToBag(FxpConstants.metricConstants.Status, err.status);
      propBag.addToBag(FxpConstants.metricConstants.StatusText, err.statusText);
      propBag.addToBag(FxpConstants.metricConstants.ErrorUrl, err.config.url);
    }

    // if oneprofile basic API failed
    if (err && err.config && err.config.url.indexOf(profileStore.ProfileAPIEndPoint) > -1) {
      self.getBasicProfileFailed(propBag);
      // ErrorCode === 114 represents  Not a valid user/ profile does'nt exist, which is return by basic profile API.
      if (err && err.data && err.data.ErrorCode === 114) {
        if (self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileMissing) {
          headerText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileMissing.ErrorMessageTitle;
          descriptionText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileMissing.ErrorMessage;
          pageTitle = CommonUtils.getTenantURLSpecificPageTitle(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileMissing.PageTitle);
        } else {
          console.log("Failed to load BasicProfileMissing error string from UIMessages");
        }
        propBag.addToBag(FxpConstants.metricConstants.GlobalErrorDetail, "Basic Profile call Failure");
        GlobalExceptionHandler.logFxpBootFailure(self.fxpLoggerService.getDefaultPropertyBagValues(propBag), `${self.sourceForTelemetry}.CheckOneProfileAndCalimStatus`, headerText, descriptionText, pageTitle);
      }
    }
    // if oneprofile claims API failed due to ITAuth Down or blank roles (for 500 and 404 httpresponses)
    if (err && err.config &&
      (err.config.url.indexOf(authStore.UserClaimsEndPoint) > -1 || err.config.url.indexOf(authStore.TenantClaimsEndPoint) > -1)) {
      self.getClaimsFailed(propBag, err.status);
    }
  }

  /**
   * A method to use log the Basic profile error
   * @method Fxp.Controllers.AppController.getBasicProfileFailed
   * @param {object} propBag A propBag Ojects need to send
   * @example <caption> Example to use getBasicProfileFailed</caption>
   * AppControllerHelper.getBasicProfileFailed(propBag)
   */
  getBasicProfileFailed(propBag: any) {
    var self = this, headerText, descriptionText, pageTitle;
    if (self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileAPIFailed) {
      headerText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileAPIFailed.ErrorMessageTitle;
      descriptionText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileAPIFailed.ErrorMessage;
      pageTitle = CommonUtils.getTenantURLSpecificPageTitle(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.BasicProfileAPIFailed.PageTitle);
    } else {
      console.log("Failed to load BasicProfileAPIFailed error string from UIMessages");
    }
    propBag.addToBag(FxpConstants.metricConstants.GlobalErrorDetail, "User basic profile call Failure");
    if (!self.$rootScope.isBlockedCSAUser) {
      GlobalExceptionHandler.logFxpBootFailure(self.fxpLoggerService.getDefaultPropertyBagValues(propBag), `${self.sourceForTelemetry}.getBasicProfileFailed`, headerText, descriptionText, pageTitle);
      return;
    }
    let errorMessageTitleForCSAUser, errorMessageForCSAUser, pageTitleCSABlockedUser = '';
    if (self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser &&
      self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser.ErrorMessageTitle) {
      errorMessageTitleForCSAUser = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser.ErrorMessageTitle;
    }
    else {
      errorMessageTitleForCSAUser = 'Access Denied';
    }
    if (self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser &&
      self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser.ErrorMessage) {
      errorMessageForCSAUser = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser.ErrorMessage;
    }
    else {
      errorMessageForCSAUser = 'You are not authorized to access ESXP by worker council.';
    }
    if (self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser &&
      self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser.PageTitle) {
      pageTitleCSABlockedUser = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.CSABlockedUser.PageTitle;
    }
    else {
      pageTitleCSABlockedUser = 'ESXP Profile Error';
    }

    GlobalExceptionHandler.logFxpBootFailure(self.fxpLoggerService.getDefaultPropertyBagValues(propBag), `${self.sourceForTelemetry}.getBasicProfileFailed`, errorMessageTitleForCSAUser, errorMessageForCSAUser, pageTitleCSABlockedUser, true);

  }

  /**
   * A method to use log the User Claims call failure error
   * @method Fxp.Controllers.AppController.getClaimsFailed
   * @param {object} propBag A propBag Ojects need to send
   * @example <caption> Example to use getClaimsFailed</caption>
   * AppControllerHelper.getClaimsFailed(propBag)
   */
  getClaimsFailed(propBag: any, statusCode?: number) {
    propBag.addToBag(FxpConstants.metricConstants.GlobalErrorDetail, "User Claims call Failure");
    var self = this, headerText, descriptionText, pageTitle;
    //V1 claims --gives 400 and v2 gives 404 when no claims found
    if (statusCode && (statusCode === 404 || statusCode === 400)) {
      headerText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.NoClaims.ErrorMessageTitle;
      descriptionText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.NoClaims.ErrorMessage;
      pageTitle = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.NoClaims.PageTitle;
    }
    else {
      if (self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.ClaimsAPIFailed) {
        headerText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.ClaimsAPIFailed.ErrorMessageTitle;
        descriptionText = self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.ClaimsAPIFailed.ErrorMessage;
        pageTitle = CommonUtils.getTenantURLSpecificPageTitle(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIMessages.ClaimsAPIFailed.PageTitle);
      } else {
        console.log("Failed to load ClaimsAPIFailed error string from UIMessages");
      }
    }
    GlobalExceptionHandler.logFxpBootFailure(self.fxpLoggerService.getDefaultPropertyBagValues(propBag), `${self.sourceForTelemetry}.getClaimsFailed`, headerText, descriptionText, pageTitle);
  }

  /**
   * A method to load data after login success
   * @method Fxp.Controllers.AppController.postLoginSuccess
   * @example <caption> Example to use postLoginSuccess</caption>
   * AppControllerHelper.postLoginSuccess()
   */
  postLoginSuccess(): void {
    let self = this;
    let eventSubscription = null;
    self.getUserPreferenceSettings();
    //make flighting service call after the get basic profile call is succeeeded
    eventSubscription = self.$rootScope.$on('OnFxpLoadedEvent', function () {
      self.dashboardService.getInitialFeatureFlagsForPlatform();
      eventSubscription();
    });
  }
  /**
   * A method to get the User Preferences Settings
   * @method Fxp.Controllers.AppController.getUserPreferenceSettings
   * @example <caption> Example to use getUserPreferenceSettings</caption>
   * AppControllerHelper.getUserPreferenceSettings()
   */
  getUserPreferenceSettings(): void {
    var self = this;
    if (self.deviceFactory.isMobile()) {
      /*In mobile view leftnav pinned by default status setting false.*/
      self.rootScopeService.setRootScopeField("isLeftNavOpen", true);
      self.rootScopeService.setRootScopeField('isLeftNavPinned', false);
    } else {
      self.getSettingsSvc();
    }
  }

  /**
   * A method to get the User Preferences Settings using settings provider service
   * @method Fxp.Controllers.AppControllerHelper.getSettingsSvc
   * @example <caption> Example to use getSettingsSvc</caption>
   * AppControllerHelper.getSettingsSvc()
   */

  private getSettingsSvc(): void {
    let self = this;
    const source_telemetry = `${this.sourceForTelemetry}.GetSettingsSvc`;
    var userUPN = self.userInfoService.getCurrentUserUpn();
    var userAliasFromUPN = userUPN.substring(0, userUPN.indexOf('@'));
    var userOID = this.getLoggedInUserOID();
    let userPreferencesStorageKey = ApplicationConstants.UserPreferencesStorageKey.replace('{0}', userAliasFromUPN);
    let getSettingsAPIStartTime = performance.now();
    self.settingsService.getSettings(SettingsType.User, userAliasFromUPN, ApplicationConstants.UserPreferencesSettings).then(function (response: any) {
      let getSettingsAPIResponseDuration = performance.now() - getSettingsAPIStartTime;
      let propbag = self.fxpLoggerService.createPropertyBag();
      propbag.addToBag(FxpConstants.metricConstants.GetSettingsAPIResponseDuration, `${getSettingsAPIResponseDuration}`);
      propbag.addToBag(FxpConstants.metricConstants.Geography, self.fxpTelemetryContext.getGeography());
      propbag.addToBag(FxpConstants.metricConstants.BrowserType, self.deviceDetector.browser);
      let currentProcessName = TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.UserSettings.FetchUserSettings';
      let eventData = new SystemEvent(currentProcessName, ComponentType.DataStore, 'Get settings API call.');
      propbag.addToBag(TelemetryConstants.CLASSNAME, "AppControllerHelper");
      propbag.addToBag(TelemetryConstants.METHODNAME, "getSettingsSvc");
      self.fxpLoggerService.logSystemEvent(source_telemetry, eventData, propbag);

      if (response && (response.data.length > 0)) {
        var userSettings = JSON.parse(response.data[0].settingValue);
        self.rootScopeService.setRootScopeField("isLeftNavOpen", userSettings.isLeftNavPinned);
        self.rootScopeService.setRootScopeField('isLeftNavPinned', userSettings.isLeftNavPinned);
        self.rootScopeService.setRootScopeField("isNotificationDNDEnabled", userSettings.isNotificationDNDEnabled);
        self.fxpContext.saveContext(userPreferencesStorageKey, response.data[0].settingValue, ApplicationConstants.FxPDbName);
      } else {
        self.setDefaultUserPreferences('as no response');
      }
      self.triggerGetBasicProfileByOID(userOID);
    }, function (error) {
      self.setDefaultUserPreferences('as error occurred.');
      self.triggerGetBasicProfileByOID(userOID);
      let propbag = self.fxpLoggerService.createPropertyBag();
      propbag.addToBag(FxpConstants.metricConstants.RequestedUserAlias, userOID);
      propbag.addToBag(FxpConstants.metricConstants.Status, error.status);
      propbag.addToBag(FxpConstants.metricConstants.StatusText, error.statusText + ' ' + error.data);
      self.fxpLoggerService.logError(source_telemetry,
        self.$rootScope.fxpUIConstants.UIMessages.GetSettingsServiceCallFailedError.ErrorMessageTitle,
        "3501",
        CommonUtils.objectToString(error),
        propbag);
      self.fxpLoggerService.logException(source_telemetry, error, propbag, null, null, ErrorSeverityLevel.Medium, ErrorCodes.OnSettingsServiceError)
    });
  }

  private triggerGetBasicProfileByOID(userOID: string): void {
    this.userProfileService.getBasicProfileByOID(userOID, null).then((loggedInUserInfo: any) => {
    });
  }

  private setDefaultUserPreferences(reason: string): void {
    var self = this;
    self.rootScopeService.setRootScopeField("isLeftNavOpen", true);
    self.rootScopeService.setRootScopeField('isLeftNavPinned', true);
    self.rootScopeService.setRootScopeField("isNotificationDNDEnabled", false);
    self.fxpLoggerService.logEvent(`${this.sourceForTelemetry}.SetDefaultUserPreferences`, `DefaultUserPreference:Set ${reason}`);
  }

  handleAdalErrorsAuthenticating(): void {
    var self = AppControllerHelper._instance;
    self.handleAdalErrors("Authenticating");
  }
  handleAdalErrorsLoadingProfile(): void {
    var self = AppControllerHelper._instance;
    self.handleAdalErrors("LoadingProfile");
  }
  handleAdalErrorsLoadingDashboard(): void {
    var self = AppControllerHelper._instance;
    self.handleAdalErrors("LoadingDashboard");
  }
  private handleAdalErrors(pageLoaderEvent: string): void {
    var self = AppControllerHelper._instance;
    const telemtry_source = `${self.sourceForTelemetry}.HandleAdalErrors`;
    var propBag = self.fxpLoggerService.createPropertyBag();
    var adalErrorMessage = "Page response time exceeded the set limit, please click Reset Application to resolve the issue. If the problem persists contact IT Support.";
    propBag.addToBag("PageLoaderEvent", pageLoaderEvent);
    propBag.addToBag(ApplicationConstants.RequestStateName, sessionStorage[ApplicationConstants.RequestStateName]);
    propBag.addToBag(ApplicationConstants.AdalLoginError, localStorage["adal.login.error"]);
    propBag.addToBag(ApplicationConstants.AdalLoginRequest, localStorage["adal.login.request"]);
    var adalErrorDescription = localStorage["adal.error.description"];
    if (CommonUtils.isNullOrEmpty(adalErrorDescription)) {
      propBag.addToBag("Message", self.$rootScope.fxpUIConstants.UIMessages.LoadTimeOutGenericError.ErrorMessage);
      self.fxpMessage.addMessage(adalErrorMessage, "reload");
      self.fxpLoggerService.logError(telemtry_source, "FxpBootstrapAdalError", ErrorCodes.AdalOnError, null, propBag, null, null, ErrorSeverityLevel.High);
    }
    else {
      var trustedSiteIssue = adalErrorDescription.indexOf("AADSTS50058") == 0;
      var adalError = adalErrorDescription.indexOf("AADSTS50079") == 0 ||
        adalErrorDescription.indexOf("AADSTS50001") == 0 ||
        adalErrorDescription.indexOf("Nonce received:") == 0;

      var errorMessage = "";
      var messageType = "error";

      if (trustedSiteIssue) {
        errorMessage = self.$rootScope.fxpUIConstants.UIMessages.LoadTimeAdalError.ErrorMessage;
      }
      else if (adalError) {
        errorMessage = "Sorry, but we're having trouble signing you in. Please try refreshing the page. If this doesn't work contact support.";
        messageType = "reload";
      }
      else {
        errorMessage = adalErrorMessage;
        messageType = "reload";
      }

      propBag.addToBag("Message", errorMessage);
      propBag.addToBag("AdalError", localStorage["adal.error"]);
      propBag.addToBag("AdalErrorDescription", adalErrorDescription);
      self.fxpMessage.addMessage(errorMessage, messageType);
      self.fxpLoggerService.logError(telemtry_source, "FxpBootstrapAdalError", ErrorCodes.AdalOnErrorWithErrorCode, null, propBag, null, null, ErrorSeverityLevel.High);
    }
  }

  private setDefaultAppRole() {
    this.$rootScope.defaultAppRole = "";
    let authStore = window["tenantConfiguration"].AuthStore;
    if (this.$rootScope.defaultAppRoleInternal) {
      this.$rootScope.defaultAppRole = this.$rootScope.defaultAppRoleInternal;
    }
    else if (!(authStore) || !(authStore.TenantClaimsEndPoint)) {
      this.$rootScope.defaultAppRole = window["tenantConfiguration"].defaultAppRole;
    }
  }

  static appControllerHelperFactory($rootScope, userProfileService, fxpGlobalStoreService, fxpTelemetryContext, fxpLoggerService, userInfoService, featureFlagService, startUpFlightConfig, settingsService, fxpContext, fxpMessage, deviceFactory, deviceDetector, plannedDownTimeService, fxpConfiguration, userClaimsService,
    FxpUIBModalService,
    FxpModalConstant, DashboardService, msalAuthenticationService) {
    return new AppControllerHelper($rootScope, userProfileService, fxpGlobalStoreService, fxpTelemetryContext, fxpLoggerService, userInfoService, featureFlagService, startUpFlightConfig, settingsService, fxpContext, fxpMessage, deviceFactory, deviceDetector, plannedDownTimeService, fxpConfiguration, userClaimsService,
      FxpUIBModalService,
      FxpModalConstant, DashboardService, msalAuthenticationService);
  }
}
