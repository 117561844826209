import { Injectable, forwardRef, Inject, EventEmitter } from "@angular/core";
import { UserInfoService, SettingsServiceProvider, AdalLoginHelperService, ErrorSeverityLevel, FxpContext } from "@fxp/fxpservices";
import { StateService } from "@uirouter/angular";
import { formatDate } from "@angular/common";
import { ConfigManagerService } from "./configmanager.service";
import { DMLoggerService } from "./dmlogger.service";
import { DmServiceAbstract } from "../abstraction/dm-service.abstract";
import { IDelegationDetailsV2 } from "./contracts/delegation.v2.service.contracts";
import { IDelegationViewModel } from "../../components/engagement-detail/engagement.model";
import { IDemandSourceIDObject, IProjectRequest, IResourceRequest } from "./contracts/staffing.service.contract";
import { IEngagementList, IPinnedEntities, IInternalEngagementType, IInternalEngagementCreationCode, IProjectList, IComponentTracker } from "./contracts/portfolio.model";
import { IEngagementViewModel } from "../../components/engagement-detail/engagement.model";
import { IFinancialRoles } from "./contracts/projectservice-functions.contract";
import { IPersonDetails } from "../../../app/components/manage-ebs-v2/tiles/entity-team-structure/entity-team-structure.component";
import { IProgressBar, ProgressBarStatus } from "../../../app/components/tiles/dm-progress-bar/dm-progress-bar.contracts";
import { IProject, IWbsStructure } from "./contracts/wbsStructures.contracts";
import { IProjectDetails, IProjectSearchResult, IEngagementDetails, IEngagementSearchResult, IEngagementListObjectV2, IProjectListObjectV2 } from "./contracts/project.service.contracts";
import { ISelectedUserAttributes, IOneProfileSerResAttr } from "../../components/tiles/type-ahead/type-ahead-contracts";
import { ISimpleContact } from "../../../app/components/shared/contacts/contacts.component";
import { ITeamDetailsV2, IEngagementDetailsV2, IProjectDetailsV2, IProjectDetailsApiV2 } from "./contracts/wbs-details-v2.contracts";
import { IWbsEditEngagementDetails, IWbsEditProjectDetails } from "./contracts/wbs.service.contracts";
import { IWeek } from "../../components/tiles/dm-schedule/dm-schedule.contracts";
import { ProjectService } from "./project.service";
import { UserPreferenceConstants, DelegationTypeText, DelegationTitleText, FeedBackEntity, RequestedState, EbsState, Services, SourceConstants, ProjectUserStatusTypes, EntityType } from "../../common/application.constants";
import moment from "moment";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { IDimensionStr, IDomainDetails, IMasterSkillsLevels, ISkillDetails } from "./contracts/one-profile.contracts";

declare const Fxp: any;
const US_PUBSEC_CLINSLIN_CODES: string[] = ["001", "003"];
const SROutEngagementCreationCodes: string[] = ["95", "98", "82", "83"];
const FederalSROutEngagementCreationCodes: string[] = ["82", "83"];
const SROutEngagementTypeCodes: string[] = ["Z000010", "Z000007", "Z000008", "Z000009"];
const ECIF_EngagementCreationCode: string = "Z2";
const ECIF_EngagementTypeCode: string = "Z000011";
const RatableProjectTypeCodes: string[] = ["Z5", "Z6"];
@Injectable()
export class SharedFunctionsService extends DmServiceAbstract {

    /**
     * Get list of acceptable file upload formats
     */
    public supportedUploadFileFormats: string[] = [
        ".pdf",
        ".msg",
        ".doc",
        ".docx",
        ".csv",
        ".txt",
        ".xls",
        ".xlsx",
        ".potx",
        ".pps",
        ".ppsm",
        ".ppt",
        ".ppsx",
        ".pptx"
    ];
    public pjmEngineeringSecurityGroupId: string = "cd2007a5-f9b7-441f-be1a-7fe3151a5736";

    private currentUserBPID: number;
    private expenseTypeCode: string;
    private internalEngagementCreationCodes: IInternalEngagementCreationCode[];
    private internalEngagementTypes: IInternalEngagementType[];
    private isSupressSelfServeAlertsFeatureEnabled: boolean;
    private isUsPubSecEnabled: boolean;
    private keysToVerifyToDisableNotifications: string[] = ["name", "description", "startDate", "endDate", "shouldCascadeUpdate"];
    private snapshotCountUpdated: EventEmitter<void>;
    private usPubSecCodes: string[];
    private conflictResourceRequestStatus: string[] = [];
    private readOnlyStatuses: string[];
    private currentUserAlias: string;
    private subConRolePartNumbers: string[];


    public constructor(
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(forwardRef(() => SettingsServiceProvider)) private settingsService: SettingsServiceProvider,
        @Inject(forwardRef(() => AdalLoginHelperService)) private fxpAdalHelper: AdalLoginHelperService,
        @Inject(forwardRef(() => FxpContext)) private fxpContext: FxpContext,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(ProjectService) private projectService: ProjectService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
    ) {
        super(dmLogger, Services.SharedFunctions);
        this.configManagerService.initialize().then(() => {
            this.internalEngagementTypes = this.configManagerService.getValue<IInternalEngagementType[]>("internalEngagmentTypes");
            this.internalEngagementCreationCodes = this.configManagerService.getValue<IInternalEngagementCreationCode[]>("internalEngagementCreationCodes");
            this.isUsPubSecEnabled = this.configManagerService.isFeatureEnabled("ShowUSPubSec");
            this.usPubSecCodes = this.configManagerService.getValue<string[]>("usPubSecCodes");
            this.isSupressSelfServeAlertsFeatureEnabled = this.configManagerService.isFeatureEnabled("DisableAlertsEmailNotifications");
            this.keysToVerifyToDisableNotifications = this.configManagerService.getValue<string[]>("keysToVerifyToDisableNotifications");
            this.expenseTypeCode = this.configManagerService.getValue<string>("ExpenseTypeCode");
            this.conflictResourceRequestStatus = this.configManagerService.getValue<string[]>("conflictResourceStatus");
            this.readOnlyStatuses = this.configManagerService.getValue<string[]>("projectUserStatusForReadOnly");
            this.subConRolePartNumbers = this.configManagerService.getValue<string[]>("subConRolePartNumbers");
        });
        this.currentUserBPID = Number(this.fxpUserInfoService.getCurrentUserData().BusinessPartnerId);
        this.snapshotCountUpdated = new EventEmitter<void>();
        this.currentUserAlias = this.fxpUserInfoService.getCurrentUserData().alias;
    }

    /**
     * Gets the current user's info formatted as an ISelectedUserAttributes object.
     * Ensures that the first name of the user is the user's preferred first name if applicable.
     */
    public getCurrentUserInfoAsSelectedUserAttr(): ISelectedUserAttributes {
        const userInfo = this.fxpUserInfoService.getCurrentUserContext().userInfo;
        return {
            userAlias: userInfo.alias,
            userName: userInfo.displayName,
            bpId: userInfo.BusinessPartnerId,
            emailName: userInfo.emailName,
            fullName: userInfo.fullName,
            firstName: userInfo.preferredFirstName ? userInfo.preferredFirstName : userInfo.firstName,
            lastName: userInfo.lastName
        };
    }

    /**
     *  get person details
     */
    public getPersonDetails(contact: ISimpleContact): IPersonDetails {
        const personDetails: IPersonDetails = {
            displayName: contact.name,
            alias: contact.alias
        };
        const emailPostFix: string = "@microsoft.com";
        const loggedInUserInfo = this.getCurrentUserInfoAsSelectedUserAttr();
        personDetails.mail = contact.alias + emailPostFix;
        if (loggedInUserInfo) {
            if (loggedInUserInfo.userAlias && contact.alias && contact.alias.toLowerCase() !== loggedInUserInfo.userAlias.toLowerCase()) {
                personDetails.userPrincipalName = contact.alias + emailPostFix;
            }
        }
        personDetails.jobTitle = contact.role;
        return personDetails;
    }

    /**
     * create's team structure with ppjm and list of pjm passed
     *
     * @param {ISelectedUserAttributes} ppjm
     * @param {ISelectedUserAttributes[]} adPpjmList
     * @returns {ITeamDetailsV2[]}
     * @memberof SharedFunctionsService
     */
    public createTeamStructure(ppjm: ISelectedUserAttributes, adPpjmList: ISelectedUserAttributes[], entity: string): ITeamDetailsV2[] {
        let managerRole = "";
        let additionalManagerRole = "";
        if (entity === "Engagement") {
            managerRole = "PPJM";
            additionalManagerRole = "ADPPJM";
        } else if (entity === "Project") {
            managerRole = "PJM";
            additionalManagerRole = "ADPJM";
        }
        const teamroles: ITeamDetailsV2[] = [];
        if (adPpjmList && adPpjmList.length) {
            for (const x of adPpjmList) {
                teamroles.push({
                    bpid: x.bpId,
                    role: additionalManagerRole,
                    name: x.userName,
                    alias: x.userAlias
                });
            }
        }
        const filtPJM = {
            bpid: ppjm && ppjm.bpId ? ppjm.bpId : undefined,
            role: managerRole,
            name: ppjm && ppjm.userName ? ppjm.userName : undefined,
            alias: ppjm && ppjm.userAlias ? ppjm.userAlias : undefined,
        };
        teamroles.push(filtPJM);
        return teamroles;
    }

    /**
     * Creates team structure if user has changed the input and constructs the team details.
     *
     *
     * @param {IOneProfileSerResAttr} ppjm
     * @param {ISelectedUserAttributes[]} adPpjmList
     * @param {string} entity
     * @returns {ITeamDetailsV2[]}
     * @memberof SharedFunctionsService
     */
    public createTeamStructureIfDirty(ppjm: IOneProfileSerResAttr, adPpjmList: ISelectedUserAttributes[], entity: string): ITeamDetailsV2[] {
        let managerRole = "";
        let additionalManagerRole = "";
        if (entity === "Engagement") {
            managerRole = "PPJM";
            additionalManagerRole = "ADPPJM";
        } else if (entity === "Project") {
            managerRole = "PJM";
            additionalManagerRole = "ADPJM";
        }
        const teamroles: ITeamDetailsV2[] = [];
        if (adPpjmList && adPpjmList.length) {
            for (const x of adPpjmList) {
                teamroles.push({
                    bpid: x.bpId,
                    role: additionalManagerRole,
                    name: x.userName,
                    alias: x.userAlias
                });
            }
        }
        const filtPJM = {
            bpid: ppjm && ppjm.BusinessPartnerId ? ppjm.BusinessPartnerId : undefined,
            role: managerRole,
            name: ppjm && ppjm.DisplayName ? ppjm.DisplayName : undefined,
            alias: ppjm && ppjm.Alias ? ppjm.Alias : undefined,
        };
        teamroles.push(filtPJM);
        return teamroles;
    }

    /**
     * Return the progress for an engagement
     */
    public getProgressForEntityBeforePJC(progressState: string): IProgressBar[] {
        switch (progressState) {
            case EbsState.Created:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }
                ];

            case EbsState.Released:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }];
            case EbsState.TechnicallyCompleted:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }];
            case EbsState.Closed:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.Completed
                }];
        }
    }

    /**
     * Return the progress for an engagement
     */
    public getProgressForEntity(progressState: string): IProgressBar[] {
        switch (progressState) {
            case EbsState.Created:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.DECO,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.RECO,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }
                ];

            case EbsState.Released:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.DECO,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.RECO,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }];
            case EbsState.DECO:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.DECO,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.RECO,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }];
            case EbsState.RECO:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.DECO,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.RECO,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.NotStarted
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }];
            case EbsState.TechnicallyCompleted:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.DECO,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.RECO,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.InProgress
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.NotStarted
                }];
            case EbsState.Closed:
                return [{
                    content: EbsState.Created,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Released,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.DECO,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.RECO,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.TechnicallyCompletedShortName,
                    status: ProgressBarStatus.Completed
                },
                {
                    content: EbsState.Closed,
                    status: ProgressBarStatus.Completed
                }];
        }
    }

    /**
     * Gets user info object that is required in internal engagements.
     *
     * @param {ITeamDetailsV2} user
     * @returns {ISelectedUserAttributes}
     * @memberof SharedFunctionsService
     */
    public getUserInfoObject(user: ITeamDetailsV2): ISelectedUserAttributes {
        if (user) {
            return {
                userAlias: user.alias,
                userName: user.name,
                bpId: user.bpid,
                emailName: "",
                fullName: user.name,
                firstName: user.name,
                lastName: user.name
            };
        }
    }

    /**
     * Get pjm info for engagement level
     *
     * @param {ITeamDetailsV2["role"]} role
     * @param {IEngagementDetailsV2} engagementDataV2
     * @returns {ITeamDetailsV2[]}
     * @memberof SharedFunctionsService
     */
    public getPjmInfoL0(role: ITeamDetailsV2["role"], engagementDataV2: IEngagementDetailsV2): ITeamDetailsV2[] {
        if (engagementDataV2 && engagementDataV2.teamStructure && engagementDataV2.teamStructure.length) {
            return engagementDataV2.teamStructure.filter((m: ITeamDetailsV2) => m.role === role);
        }
        return undefined;
    }

    /**
     *  get pjm info from team structure in projects.
     *
     * @param {ITeamDetailsV2["role"]} role
     * @param {IProjectDetailsV2} projectDataV2
     * @returns {ITeamDetailsV2[]}
     * @memberof SharedFunctionsService
     */
    public getPjmInfoL1(role: ITeamDetailsV2["role"], projectDataV2: IProjectDetailsV2): ITeamDetailsV2[] {
        if (projectDataV2 && projectDataV2.teamStructure && projectDataV2.teamStructure.length) {
            return projectDataV2.teamStructure.filter((m: ITeamDetailsV2) => m.role === role);
        }
        return undefined;
    }

    /**
     * Gets the minimum start date from the given engagement details and its projects, and the maximum end date.
     * @param engDetails
     */
    public getChildMinStartMaxEndDate(engDetails: IEngagementDetailsV2): {
        minStartDate: Date;
        maxEndDate: Date;
    } {
        if (engDetails && engDetails.projects && engDetails.projects[0]) {
            let firstProjectChildStartDate: moment.Moment = moment(engDetails.projects[0].startDate);
            let lastProjectChildEndDate: moment.Moment = moment(engDetails.projects[0].endDate);
            engDetails.projects.forEach((project) => {
                const serStartDate = moment(project.startDate);
                if (serStartDate.isBefore(firstProjectChildStartDate)) {
                    firstProjectChildStartDate = serStartDate;
                }
                const serEndDate = moment(project.endDate);
                if (serEndDate.isAfter(lastProjectChildEndDate)) {
                    lastProjectChildEndDate = serEndDate;
                }
            });
            return {
                minStartDate: firstProjectChildStartDate.toDate(),
                maxEndDate: lastProjectChildEndDate.toDate()
            };
        }
        return undefined;
    }

    /**
     * Verifies if the given pub sec code is the US pub sec code.
     * @param pubSecCode
     */
    public verifyIsUsPubSec(pubSecCode: string): boolean {
        return this.isUsPubSecEnabled && (this.usPubSecCodes.indexOf(pubSecCode) > -1);
    }

    /**
     * Verifies if the given pub sec code clinslin api needs to be called
     * @param pubSecCode
     */
    public shouldCallClinSlinApi(pubSecCode: string): boolean {
        return this.isUsPubSecEnabled && (US_PUBSEC_CLINSLIN_CODES.indexOf(pubSecCode) > -1);
    }

    /**
     * Sorts a list by its engagement IDs; sorts from highest ID to lowest ID
     * @param list
     */
    public sortEngagementListByEngagementId(list: any[]): void {
        this.sortListByProperty(list, "engagementId");
    }

    /**
     * Sorts a list by property
     *
     * @param {any[]} list
     * @param {string} property
     * @memberof SharedFunctionsService
     */
    public sortListByProperty(list: any[], property: string): void {
        if (list && list.length && list.length > 1) {
            list.sort((left, right): number => {
                if (left[property] < right[property]) {
                    return 1;
                }
                if (left[property] > right[property]) {
                    return -1;
                }
                return 0;
            });
        }
    }

    /**
     * Track by filter to track by the item id (item can be project, service, etc)
     * @param index
     * @param item
     */
    public itemIdTrackByFunction(index: number,
        item: {
            [id: string]: string | number;
        }): string | number {
        return item.id;
    }

    /* Gets the contract type for the entity based on the contract types of the projects.
     * If the projects have more than one contract type, the type will be Mixed.
     * If there is only one project, or one contract type for all projects, the type will be that contract type.
     */
    public getContractType(projects: IProjectSearchResult[] | IProjectDetails[] | IProjectListObjectV2[] | IProjectList[] | IProjectDetailsV2[]): string {
        if (!projects || projects.length === 0 || !projects[0]) {
            return undefined;
        }
        let keyAttribute: string;
        /* Each type of input has a different key that the values are stored on, so we check the type and then grab the key */
        /* In cases of bad API data, some projects may satisfy the interface but their contract type will be an empty string,
        so we specifically check for undefined in order to account for these empty strings.*/
        if (
            (projects[0] as IProjectSearchResult /* Also takes in IProjectListObjectV2*/).contractType !== undefined
            && (projects[0] as IProjectSearchResult).contractType !== null
        ) {
            keyAttribute = "contractType";
        }
        if (
            (projects[0] as IProjectDetails /* Also takes in IProjectList*/).typeOfContract !== undefined
            && (projects[0] as IProjectDetails).typeOfContract !== null
        ) {
            keyAttribute = "typeOfContract";
        }

        /* Using the key we selected, check if all the items match or not and return appropriate contract type */
        if (projects.length === 1) {
            return projects[0][keyAttribute];
        }
        const contractType: string = projects[0][keyAttribute];
        for (const value of projects) {
            if (value[keyAttribute] && value[keyAttribute] !== contractType) {
                return "Mixed";
            }
        }
        return contractType;
    }

    /**
     * Is the given Engagement Internal or a Customer Delivery Engagement
     * @param e
     */
    public isEngagementInternal(e: IEngagementDetails | IEngagementSearchResult | IEngagementList | IEngagementViewModel | IEngagementListObjectV2 | IEngagementDetailsV2 | IWbsStructure): boolean {
        const projectTypeCode: string = (e as IEngagementDetails /* All types but V2 */).projectTypeCode || (e as IEngagementListObjectV2 | IEngagementDetailsV2 | IWbsStructure).engagementTypeCode;
        if (projectTypeCode &&
            this.internalEngagementTypes &&
            this.internalEngagementTypes.length &&
            this.internalEngagementCreationCodes &&
            this.internalEngagementCreationCodes.length &&
            (this.internalEngagementCreationCodes.filter((types: IInternalEngagementCreationCode) => types.typeDescription.toLowerCase() === projectTypeCode.toLowerCase()).length ||
                this.internalEngagementTypes.filter((types: IInternalEngagementType) => types.typeCode.toLocaleLowerCase() === projectTypeCode.toLocaleLowerCase()).length)) {
            /* Depending on the API, we use Internal Engagement Creation Codes type description ("pre-sales", "demand-gen generic", etc)
             or we use the Internal Engagement Type Code  ("Z000008", "Z000007", etc) */
            return true;
        }
        return false;
    }

    /**
     * Is the given Engagement Id Internal or a Customer Delivery Engagement
     * Do not use this method for determining if engagement object exists
     * @param engagementId
     */
    public isEngagementIdInternal(engagementId: string): boolean {
        return engagementId.charAt(0) === "I";
    }

    /**
     * Is the given Engagement or project id valid or not
     * Do not use this method for determining if engagement object exists
     * @param wbsId
     */
    public validEngagementOrProjectId(wbsId: string): boolean {
        const wbsIdRegex = /^[a-zA-Z]{1}\.[0-9]{10}(\.[0-9]{6})?$/;
        if (!wbsId || !wbsId.match(wbsIdRegex)) {
            return false;
        }
        return true;
    }

    /**
     * Is the listed PJM BPID the same BPID as the logged in user
     * @param pjMBpid
     */
    public isBPIDCurrentUser(pjMBpid: string): boolean {
        return (pjMBpid && (Number(this.currentUserBPID) === Number(pjMBpid)));
    }

    /**
     * Converts simple words to have an S at the end if the counter has a value of 0 or greater than 1.
     * Can also hand capital words, based on caps boolean
     * For example engagement -> engagements. ENGAGEMENT -> ENGAGEMENTS (if caps is true)
     * @param word
     * @param count
     * @param caps
     */
    public getWordPluralWithS(word: string, count: number, caps: boolean): string {
        if (count > 1 || count === 0) {
            let s = "s";
            if (caps) {
                s = "S";
            }
            return word + s;
        }
        return word;
    }

    /**
     * Loads pinned projects from the user's preferences to include in their list of Engagements throughout the app
     */
    public getPinnedEntitiesFromUserPreferences(): any { // Cannot return type  Promise<IPinnedEntities>  due to incompatibility with fxpUserInfoService.getCurrentUser
        let pinnedEntities: IPinnedEntities = {
            projectId: [],
            engagementId: []
        };
        return this.settingsService.getSettings(
            Fxp.Common.SettingsType.User,
            this.fxpUserInfoService.getCurrentUser(),
            [UserPreferenceConstants.MyPortfolioProjects]
        ).then((response: any) => { // FXP has incorrect typing on their end response, so we need to cast as any in order to grab the real response
            if (response && response.data && response.data.length) {
                pinnedEntities = JSON.parse(response.data[0].settingValue);
            }
            return pinnedEntities;
        }).catch((error) => {
            const errorMessage = this.getErrorMessage(error, "Unable to retrieve pinned entities from user preferences.");
            this.logError(SourceConstants.Method.GetPinnedEntitiesFromUserPreferences, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            return pinnedEntities;
        });
    }

    /**
     * Get engagements or projects delegated by user
     */
    public getAllActiveDelegatedByListV2(): Promise<IDelegationDetailsV2[]> {
        return this.projectService.getMyDelegatedBy(this.currentUserAlias).then((response: IDelegationDetailsV2[]) => {
            if (response) {
                return response.filter((item) => (item.status.name === "Active"));
            }
            return [];
        }).catch((error) => {
            const errorMessage = this.getErrorMessage(error, "Unable to retrieve all active delegated.");
            this.logError(SourceConstants.Method.GetAllActiveDelegatedByListV2, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            return Promise.resolve([]);
        });
    }

    /**
     * Get engagements or projects delegated to user
     */
    public getAllActiveDelegatedToListV2(): Promise<IDelegationDetailsV2[]> {
        return this.projectService.getMyDelegatedTo(this.currentUserAlias).then((response: IDelegationDetailsV2[]) => {
            if (response) {
                return response.filter((item) => (item.status.name === "Active"));
            }
            return [];
        }).catch((error) => {
            const errorMessage = this.getErrorMessage(error, "Unable to retrieve all active delegated.");
            this.logError(SourceConstants.Method.GetAllActiveDelegatedToListV2, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            return Promise.resolve([]);
        });
    }

    /**
     * Gets the selected Engagement ID off of the state parameters (from the query URL).
     * Can also get the engagement ID from a project ID on the state parameters. Returns undefined if it cannot find
     * an engagement ID or project ID.
     */
    public getSelectedEngagementId(stateService: StateService): string {
        if (stateService.params.engagementId) {
            return stateService.params.engagementId;
        }
        if (stateService.params.projectId) {
            return this.getEngagementIdFromProjectId(stateService.params.projectId);
        }
        return undefined;
    }

    /**
     * Gets the selected Project ID off of the state parameters (from the query URL).
     * Only to be used on entry.projectDetail child components, or else will return undefined
     */
    public getSelectedProjectId(stateService: StateService): string {
        return stateService.params.projectId;
    }

    /**
     * Returns true is this project context
     */
    public isProjectContext(stateService: StateService): boolean {
        return !!this.getSelectedProjectId(stateService);
    }

    /**
     * Gets the engagement ID for the given project ID by parsing the project ID. If the input is not a valid project ID,
     * (1 letter, a period, 10 digits, a period, 6 digits), then the function will return undefined. Otherwise, it will return the engagement ID.
     * @param projectId
     */
    public getEngagementIdFromProjectId(projectId: string): string {
        const projectIdRegex = /^[a-zA-Z]{1}\.[0-9]{10}\.[0-9]{6}$/;
        if (!projectId || !projectId.match(projectIdRegex)) {
            return undefined;
        }
        const lastIndex: number = projectId.lastIndexOf(".");
        return projectId.substring(0, lastIndex);
    }

    /**
     * Sets focus to the given element on the UI based on its ID
     * @param id
     * @param isTimeOut
     */
    public focus(id: string, isTimeOut: boolean): void {
        if (isTimeOut) {
            setTimeout(() => {
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.focus();
                }
            }, 300);
        } else {
            const element = window.document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    }

    /**
     * Move focus to Next/previous element for accessibility tooling
     * @param event
     * @param id
     * @param defaultId
     */
    public moveFocus(event: KeyboardEvent, id: string, defaultId: string): void {
        event.preventDefault();
        const element: HTMLElement = document.getElementById(id);
        if (element && element.hasAttribute("disabled")) {
            this.focus(defaultId, true);
        } else {
            this.focus(id, true);
        }
    }

    /**
     * Move the focus on left and right arrow key press in case of nav bar
     * @param id
     */
    public moveFocusForNavBarArrowKey(tabsContent: any[], id: string, focusDirection: string): void {
        let focusId;
        if (tabsContent) {
            for (let i = 0; i < tabsContent.length; i++) {
                if (tabsContent[i].id === id) {
                    if (focusDirection === "right" && tabsContent[i + 1].id) {
                        focusId = tabsContent[i + 1].id;
                    } else if (focusDirection === "left" && i > 0 && tabsContent[i - 1].id) {
                        focusId = tabsContent[i - 1].id;
                    } else {
                        focusId = tabsContent[i].id;
                    }

                }
            }
        }
        this.focus(focusId, true);
    }

    /**
     * Gets a list of Managers and Delegated Managers on an Engagement L0 level and its child Projects L1 level
     * @param engagement
     */
    public getListofPjM(engagement: IEngagementDetails): string[] {
        if (!engagement) {
            return undefined;
        }

        // Get ppjms and delegated ppjms for engagement
        let listOfPjM: string[] = this.getListOfPjMForEngagementOnly(engagement);

        // Get pjms and delegated pjms for projects
        engagement.projects.forEach((project) => {
            listOfPjM = listOfPjM.concat(this.getListOfPjMForProjectOnly(project));
        });

        listOfPjM = this.getArrayWithoutDupes(listOfPjM);
        return listOfPjM;
    }

    /**
     * Get aliases list of all pjm's and delegated pjm's.
     *
     * @param {IEngagementDetailsV2} engagementData
     * @returns {string[]}
     * @memberof SharedFunctionsService
     */
    public getListofPjmV2(engagementData: IEngagementDetailsV2): string[] {
        if (!engagementData) {
            return undefined;
        }

        // Get ppjms and delegated ppjms for engagement
        let listOfPjm: string[] = [];
        if (engagementData.pPjm && engagementData.pPjm.alias) {
            listOfPjm.push(engagementData.pPjm.alias);
        }

        if (engagementData.delPPjm && engagementData.delPPjm.alias) {
            listOfPjm.push(engagementData.delPPjm.alias);
        }

        // Get pjms and delegated pjms for projects under engagement
        for (const project of engagementData.projects) {
            if (project.pjm && project.pjm.alias) {
                listOfPjm.push(project.pjm.alias);
            }

            if (project.delPjm && project.delPjm.alias) {
                listOfPjm.push(project.delPjm.alias);
            }
        }

        listOfPjm = this.getArrayWithoutDupes(listOfPjm);
        return listOfPjm;
    }

    /**
     * Get aliases list of all pjm's, apjms and delegated pjm's at L0 level only
     *
     * @param {IEngagementDetailsV2} engagementData
     * @returns {string[]}
     * @memberof SharedFunctionsService
     */
    public getListofPjmEngagementOnlyV2(engagementData: IEngagementDetailsV2): string[] {
        if (!engagementData) {
            return undefined;
        }

        // Get ppjms and delegated ppjms for engagement
        let listOfPjm: string[] = [];
        if (engagementData.pPjm && engagementData.pPjm.alias) {
            listOfPjm.push(engagementData.pPjm.alias);
        }

        if (engagementData.delPPjm && engagementData.delPPjm.alias) {
            listOfPjm.push(engagementData.delPPjm.alias);
        }

        if (engagementData.adPPjm && engagementData.adPPjm.length) {
            for (const m of engagementData.adPPjm) {
                if (m && m.alias) {
                    listOfPjm.push(m.alias);
                }
            }
        }

        listOfPjm = this.getArrayWithoutDupes(listOfPjm);
        return listOfPjm;
    }

    /**
     * Get aliases list of all pjm's and delegated pjm's.
     *
     * @param {IProjectDetailsV2} projectData
     * @returns {string[]}
     * @memberof SharedFunctionsService
     */
    public getListofPjmProjectContextV2(projectData: IProjectDetailsV2[], includeApjms: boolean = false): string[] {
        if (!projectData) {
            return undefined;
        }

        // Get pjms and delegated pjms for projects under engagement
        let listOfPjm: string[] = [];
        for (const project of projectData) {
            if (project.pjm && project.pjm.alias) {
                listOfPjm.push(project.pjm.alias);
            }

            if (project.delPjm && project.delPjm.alias) {
                listOfPjm.push(project.delPjm.alias);
            }

            if (includeApjms && project.adPjm) {
                for (const m of project.adPjm) {
                    if (m && m.alias) {
                        listOfPjm.push(m.alias);
                    }
                }
            }
        }

        listOfPjm = this.getArrayWithoutDupes(listOfPjm);
        return listOfPjm;
    }

    /**
     * Get aliases list of additional managers.
     *
     * @param {IEngagementDetailsV2} engagementData
     * @returns {string[]}
     * @memberof SharedFunctionsService
     */
    public getListofAdPjmV2(engagementData: IEngagementDetailsV2): string[] {
        let listOfAdPjm: string[] = [];
        if (!engagementData) {
            return listOfAdPjm;
        }

        // Get additional ppjms for engagement
        if (engagementData.adPPjm && engagementData.adPPjm.length) {
            for (const m of engagementData.adPPjm) {
                if (m && m.alias) {
                    listOfAdPjm.push(m.alias);
                }
            }
        }

        // Get additional ppjms for projects under engagement

        for (const project of engagementData.projects) {
            if (project && project.adPjm) {
                for (const m of project.adPjm) {
                    if (m && m.alias) {
                        listOfAdPjm.push(m.alias);
                    }
                }
            }
        }
        listOfAdPjm = this.getArrayWithoutDupes(listOfAdPjm);
        return listOfAdPjm;
    }

    /**
     * Gets a list of Managers and  Delegated Managers for Engagement L0 level (not including child Projects L1 level)
     * @param engagement
     */
    public getListOfPjMForEngagementOnly(engagement: IEngagementDetails): string[] {
        if (!engagement) {
            return undefined;
        }
        const listOfPjM: string[] = [];
        if (engagement.pPjMAlias) {
            listOfPjM.push(engagement.pPjMAlias);
        }
        if (engagement.delegatedPPjMAlias && listOfPjM.indexOf(engagement.delegatedPPjMAlias) === -1) {
            listOfPjM.push(engagement.delegatedPPjMAlias);
        }
        return listOfPjM;
    }

    /**
     * Gets list of Managers for Project L1 level only
     * @param project
     */
    public getListOfPjMForProjectOnly(project: IProjectDetails): string[] {
        const listOfPjM: string[] = [];
        // Get pjms and delegated pjms for projects
        if (project.delegatedPPjMAlias && listOfPjM.indexOf(project.delegatedPPjMAlias) === -1) {
            listOfPjM.push(project.delegatedPPjMAlias);
        }

        // check distinct projectmanager of project
        if (project.pjMAlias && listOfPjM.indexOf(project.pjMAlias) === -1) {
            listOfPjM.push(project.pjMAlias);
        }
        return listOfPjM;
    }

    /**
     * Creates and returns a new array of strings that contains only distinct string items, case-insensitive
     */
    public getArrayWithoutDupes(arr: string[]): string[] {
        if (!arr || arr.length <= 1) {
            return arr;
        }
        const newArray: string[] = [];
        for (const s of arr) {
            const n: string = s.toLowerCase();
            if (n && newArray.indexOf(n) < 0) {
                newArray.push(n);
            }
        }
        return newArray;
    }

    /**
     * Creating request header with end point
     * @param endPoint
     */
    public createRequestHeader(endPoint: string): string {
        let accessToken = this.fxpAdalHelper.getCachedToken(endPoint);
        if (!accessToken) {
            this.fxpAdalHelper.acquireToken(endPoint, (error, token) => {
                if (token) {
                    accessToken = token;
                }
            });
        }
        return "Bearer " + accessToken;
    }

    /**
     * Used to verify if alerts needs to be disabled, added a seperate method to add additional conditional checks in future.
     */
    public disableEmailAlertsNotifications(): boolean {
        return this.isSupressSelfServeAlertsFeatureEnabled;
    }

    /**
     * Verify if configured properties are changed && feature enabled then disable notifications
     */
    public disableEmailAlertsNotificationsUpdateEBS(changedProperties: IWbsEditEngagementDetails | IWbsEditProjectDetails): boolean {
        let isPropertyExistsInConfiguredKeys: boolean = false;
        if (changedProperties && this.isSupressSelfServeAlertsFeatureEnabled) {
            for (const key in changedProperties) {
                if (this.keysToVerifyToDisableNotifications.indexOf(key) === -1) {
                    return false;
                } else {
                    isPropertyExistsInConfiguredKeys = true;
                }
            }
        }
        return isPropertyExistsInConfiguredKeys;
    }

    /**
     * Creates a list of WBS IDs from a list of projects' services that match the expense type code
     * @param projects
     */
    public getExpenseTypeWbsIds(projects: IProjectDetailsApiV2[]): string[] {
        if (!projects) {
            return undefined;
        }
        const wbsIds: string[] = [];
        if (projects) {
            for (const projectDetails of projects) {
                for (const serviceDetails of projectDetails.services) {
                    const expenseTypeWbs = serviceDetails.tasks.filter((wbsL3) => wbsL3.workPackageType === this.expenseTypeCode)[0];
                    if (expenseTypeWbs && expenseTypeWbs.id) {
                        wbsIds.push(expenseTypeWbs.id);
                    }
                }
            }
        }
        return wbsIds;
    }

    /**
     * The cache object Converts the task attribute wbsl3Name to the attribute named wbsL3Name.
     * Returns the projectDetails array with converted attributes, or returns undefined if projectDetails was undefined.
     * Todo: ask PMS team to update their API
     * @param projectDetails
     */
    public convertAttributeCapitalization(projectDetails: IProjectDetails[]): IProjectDetails[] {
        if (projectDetails) {
            for (const proj of projectDetails) {
                if (proj.services) {
                    for (const service of proj.services) {
                        if (service.wbsL3s) {
                            for (const task of service.wbsL3s) {
                                task.wbsL3Name = task["wbsl3Name"];
                            }
                        }
                    }
                }
            }
        }
        return projectDetails;
    }

    /**
     * Gets the internal engagement type/description from the list of internal engagement types.
     */
    public getInternalEngagementType(type: string): string {
        if (type && this.internalEngagementTypes && this.internalEngagementTypes.length
            && this.internalEngagementTypes.filter((types: IInternalEngagementType) => types.typeCode.toLowerCase() === type.toLowerCase()).length) {
            return this.internalEngagementTypes.filter((types: IInternalEngagementType) => types.typeCode.toLowerCase() === type.toLowerCase())[0].typeDescription;
        } else {
            return undefined;
        }
    }

    /**
     * Delays the code execution
     * @param timeInMilliseconds
     */
    public delayExecution(timeInMilliseconds: number): Promise<any> {
        return new Promise((resolve) => {
            setTimeout(resolve, timeInMilliseconds);
        });
    }

    /**
     * update the snapshot count
     */
    public getSnapshotCountUpdated(): EventEmitter<void> {
        return this.snapshotCountUpdated;
    }

    /**
     * Returns boolean to check if the rolepart number is cleared or not
     * @param rolePartNumber
     * @param rolesList
     */
    public isClearedRole(rolePartNumber: string, rolesList: IFinancialRoles[]): boolean {
        if (rolesList && rolesList.length > 0) {
            const roleRetrieved = rolesList.filter((role) => role.rolePartNumber === rolePartNumber);
            if (roleRetrieved && roleRetrieved.length > 0) {
                return roleRetrieved[0].isCleared;
            }
        }
        return false;
    }

    /**
     * Sort the Engagements and Projects by fields
     * @param {string} columnField
     * @public
     * @memberof GridDataComponent
     */
    public sortEntitiesByColumnName(columnField: string): (entity1: IEngagementList | IProjectList | IWeek, entity2: IEngagementList | IProjectList | IWeek) => number {
        return (entity1: IEngagementList | IProjectList | IWeek, entity2: IEngagementList | IProjectList | IWeek): number => {

            // to resolve the situation when entity's columnField is empty
            if (!entity1[columnField] && !entity2[columnField]) {
                return 0;
            } else if (!entity1[columnField]) {
                return -1;
            } else if (!entity2[columnField]) {
                return 1;
            }

            if (columnField === "startDate" || columnField === "endDate") {
                if (moment(entity1[columnField]).isAfter(moment(entity2[columnField]))) {
                    return 1;
                } else if (moment(entity1[columnField]).isBefore(moment(entity2[columnField]))) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                if (entity1[columnField].toString().trim().toLowerCase() > entity2[columnField].toString().trim().toLowerCase()) {
                    return 1;
                } else if (entity1[columnField].toString().trim().toLowerCase() < entity2[columnField].toString().trim().toLowerCase()) {
                    return -1;
                } else {
                    return 0;
                }
            }
        };
    }

    /**
     * Use the provided Project Manager, Primary Project Manager, and Delegation Information to create delegation data to show on UI
     * Input pjm is pPjm if it's from engagement, is pjm if it's from project
     */
    public setDelegationInformation(pjmBpId: string, delegatedBpId: string, pjmName: string, delegatedName: string): IDelegationViewModel {
        if (!delegatedBpId) {
            return undefined; // just return empty data if there is no delegation
        }

        // if delegatedBpID = currentUserBPID meaning we have to show on UI the project/engagement is delegated by others to current user
        if (this.isBPIDCurrentUser(delegatedBpId)) {
            return {
                delegationFullName: pjmName,
                delegationType: DelegationTypeText.DelegationInward,
                delegationTitle: DelegationTitleText.DelegationInward
            };
        } else {// if user is not the person project/engagement is delegated to, then we always show the outgoing delegation to whom
            return {
                delegationFullName: delegatedName,
                delegationType: DelegationTypeText.DelegationOutward,
                delegationTitle: DelegationTitleText.DelegationOutward
            };
        }
    }

    /**
     * Creates an array of Demand Source ID objects by creating objects that consist of the project's ID
     * and the project's Compass One Package ID.
     * Returns an array of the ID objects for the given project data. Will return an empty array if there was no project data.
     */
    public getInputObjectForSearchApi(projectData: IProjectDetails[] | IProjectDetailsV2[]): IDemandSourceIDObject[] {
        const demandSourceObject: IDemandSourceIDObject[] = [];
        if (projectData) {
            for (const project of projectData) {
                demandSourceObject.push({
                    ProjectID: (project as IProjectDetailsV2).id || (project as IProjectDetails).projectId,
                    CompassOnePackageID: project.compassOnePackageId
                });
            }
        }
        return demandSourceObject;
    }

    /**
     * Creates a comma-separated list from a given array.
     * If the array is empty or undefined, then an empty string is returned.
     * If only one item exists, it will be printed. If two items exist, they will be printed with the word "and" in between.
     * If 3 or mote items exist, they will be properly formatted into a list: for example: "a, b, c, and d".
     * The attribute param can index into lists of objects and create a list with the given attribute.
     * Use the ampersand flag to determine if the string should have the word "and" or "&".
     *
     * @param {string[] | object[]} arr
     * @param {boolean} ampersand
     * @param {string} [attribute]
     * @returns {string}
     * @memberof WBSService
     */
    public createCommaSeparatedString(arr: string[] | object[], ampersand: boolean, attribute?: string): string {
        if (!arr || !arr.length) {
            return "";
        }
        const and: string = ampersand ? "&" : "and";
        if (!attribute) {
            if (arr.length === 1) {
                return arr[0] as string;
            }
            if (arr.length === 2) {
                return arr[0] + " " + and + " " + arr[1];
            }
            if (arr.length > 2) {
                arr[arr.length - 1] = and + " " + arr[arr.length - 1];
            }
        } else {
            if (arr.length === 1) {
                return arr[0][attribute];
            }
            if (arr.length === 2) {
                return arr[0][attribute] + " " + and + " " + arr[1][attribute];
            }
            if (arr.length > 2) {
                arr = (arr as object[]).map((x: any, index: number) => {
                    if (index + 1 === arr.length) {
                        return and + " " + x[attribute];
                    }
                    return x[attribute];
                });
            }
        }
        return arr.join(", ");
    }

    /**
     * Gets the number of conflict resources based on filtering on the given date.
     * @param {boolean} checkForStartDate
     * @param {Date} dateModified
     * @param {IProjectRequest[]} resourceRequests
     */
    public getCountOfResourceConflicts(checkForStartDate: boolean, dateModified: Date, resourceRequests: IProjectRequest[]): number {
        const filteredResourceView = Object.assign([], resourceRequests);
        let resourcesWithConflict = 0;
        if (resourceRequests && resourceRequests.length) {
            filteredResourceView.forEach((projRequest: IProjectRequest) => {
                if (checkForStartDate) {
                    resourcesWithConflict = resourcesWithConflict + projRequest.ResourceRequests.filter((resRequest: IResourceRequest) => resRequest.ScheduledStartDate && (moment(resRequest.ScheduledStartDate).isBefore(dateModified, "day")) && this.conflictResourceRequestStatus.indexOf(resRequest.ResourceRequestStatusEnum) >= 0).length;
                } else {
                    resourcesWithConflict = resourcesWithConflict + projRequest.ResourceRequests.filter((resRequest: IResourceRequest) => resRequest.ScheduledEndDate && (moment(resRequest.ScheduledEndDate).isAfter(dateModified, "day")) && this.conflictResourceRequestStatus.indexOf(resRequest.ResourceRequestStatusEnum) >= 0).length;
                }
            });
        }
        return resourcesWithConflict;
    }

    /**
     * Checks if the project is read only or not based on its user status code.
     * This is a check for periodic billing as well as other codes.
     * Projects that are readonly are likely missing crucial information like team data and will cause downstream errors if not handled appropriately.
     *
     * @param {IProjectDetailsV2} project
     * @returns {boolean}
     * @memberof SafeLimitViewModelService
     */
    public isProjectReadOnly(project: IProjectDetailsV2 | IProject): boolean {
        for (const status of this.readOnlyStatuses) {
            if (project.userStatusCode.toUpperCase().indexOf(status) >= 0) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds an item to array if it doesn't exists and replaces the item if it exists.
     *
     * @param {*} item
     * @param {any[]} array
     * @param {string} matchProp
     * @memberof SharedFunctionsService
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public addOrReplaceItem(item: any, array: any[], matchProp: string): void {
        if (item && array && array.length === 0) {
            array.push(item);
        }
        if (item && array && array.length > 0) {
            const index = array.findIndex((x) => x[matchProp] === item[matchProp]);
            if (index === -1) {
                array.push(item);
            } else {
                array[index] = item;
            }
        }
    }

    /**
     * Sorts a list by property based on direction
     *
     * @param {string} property
     * @param {boolean} order
     * @param {any[]} list
     * @param {string} collisonProperty
     * @memberof SharedFunctionsService
     */
    public sortListByPropertyBasedOnOrder(property: string, order: boolean, list: any[], collisonProperty?: string): void {
        const direction = order ? 1 : -1;
        if (list && list.length && list.length > 1) {
            list.sort((left, right): number => {
                // Higher priority is first when direction is -1
                if (left[property] < right[property]) {
                    return -1 * direction;
                }
                if (left[property] > right[property]) {
                    return 1 * direction;
                }
                if (collisonProperty && left[property] === right[property]) {
                    // CollisionProperty is sorted in ascending when direction is -1
                    if (left[collisonProperty] > right[collisonProperty]) {
                        return -1 * direction;
                    }
                    if (left[collisonProperty] < right[collisonProperty]) {
                        return 1 * direction;
                    }
                }
                return 0;
            });
        }
    }

    /**
     * Transform date into a valid format.
     *
     * @param {Date} date
     * @returns {string}
     * @memberof SharedFunctionsService
     */
    public transformDate(date: Date, format: string): string {
        return formatDate(date, format, "en-US");
    }

    /** Counts the number of digits in integers.
     *
     * @param {number} num
     * @returns {number}
     * @memberof SharedFunctionsService
     */
    public getNumberOfDigits(num: number): number {
        return Math.max(Math.floor(Math.log(Math.abs(num)) * Math.LOG10E), 0) + 1;
    }

    /**
     * Checks if the project is of type T&M BIF [using statuscode]
     *
     * @param {string} userStatusCode
     * @returns {boolean}
     * @memberof SharedFunctionsService
     */
    public isProjectECIF(userStatusCode: string): boolean {
        const userStatusCodeArray: string[] = userStatusCode.split("/");
        if (userStatusCodeArray && userStatusCodeArray.length && (userStatusCodeArray.indexOf("DIR") > -1)) {
            return true;
        }

        return false;
    }

    /**
     * 
     * @param userStatusCode 
     * @returns ProjectUserStatusType
     * @memberof SharedFunctionsService
     */
    public getProjectOrServiceUserStatus(userStatusCode: string): string {
        const userStatusCodeUpperCase = userStatusCode.toLocaleUpperCase();

        if (userStatusCodeUpperCase.includes("DIR")) {
            return ProjectUserStatusTypes.ECIF;
        }
        else if (userStatusCodeUpperCase.includes("IND")) {
            return ProjectUserStatusTypes.ECIFPartial;
        }
        else if (userStatusCodeUpperCase.includes("JPM")) {
            return ProjectUserStatusTypes.JPM;
        }
        else if (userStatusCodeUpperCase.includes("VLEA")) {
            return ProjectUserStatusTypes.VLEA;
        }
        else if (userStatusCodeUpperCase.includes("PRP")) {
            return ProjectUserStatusTypes.Prepay;
        }
        else if (userStatusCodeUpperCase.includes("MDL")) {
            return ProjectUserStatusTypes.DeletedProject;
        }
    }

    /**
     * Checks deal is of type global using statuscode GLO
     *
     * @param {string} userStatusCode
     * @returns {boolean}
     * @memberof SharedFunctionsService
     */
    public isGlobalDeal(userStatusCode: string): boolean {
        if (userStatusCode) {
            const userStatusCodeArray: string[] = userStatusCode.split("/");
            if (userStatusCodeArray && userStatusCodeArray.length && (userStatusCodeArray.indexOf("GLO") > -1)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Checks if the feedback is expired or not and launches Feedback model
     *
     * @param {FeedBackEntity} entityName
     * @memberof SharedFunctionsService
     */
    public shouldLaunchFeedbackModal(entityName: FeedBackEntity): boolean {
        if (sessionStorage.getItem(entityName)) {
            let feedBackEntityDetails: IComponentTracker = JSON.parse(sessionStorage.getItem(FeedBackEntity.Portfolio));
            if (entityName.toLowerCase() === FeedBackEntity.ManageEBS.toLowerCase()) {
                feedBackEntityDetails = JSON.parse(sessionStorage.getItem(FeedBackEntity.ManageEBS));
            }
            if (entityName.toLowerCase() === FeedBackEntity.SummaryV2.toLowerCase()) {
                feedBackEntityDetails = JSON.parse(sessionStorage.getItem(FeedBackEntity.SummaryV2));
            }
            const noOfTimesEntityVisitedToLaunchFeedback = this.configManagerService.getValue<any>("userPreference").noOfTimesEntityVisitedToLaunchFeedback;
            if (feedBackEntityDetails && feedBackEntityDetails.numberOfTimesVisited >= noOfTimesEntityVisitedToLaunchFeedback) {
                return true;
            } else {
                const entityVisitDetails: IComponentTracker = {
                    numberOfTimesVisited: feedBackEntityDetails.numberOfTimesVisited + 1
                };
                sessionStorage.setItem(entityName, JSON.stringify(entityVisitDetails));
                return false;
            }
        } else {
            const entityVisitDetails: IComponentTracker = {
                numberOfTimesVisited: 1
            };
            sessionStorage.setItem(entityName, JSON.stringify(entityVisitDetails));
            return false;
        }
    }

    /**
     *  Gets the Requested state based on the sap status of wbs entity
     * @private
     * @param {string} requestedState
     * @memberof WbsEngagementStateModalComponent
     */
    public getRequestedState(requestedState: string): RequestedState {
        switch (requestedState) {
            case "Technically Completed":
                return RequestedState.RequestedForTechnicallyCompleted;
            case "Released":
                return RequestedState.RequestedForRelease;
            case "Locked":
                return RequestedState.RequestForLocked;
            case "UnLocked":
                return RequestedState.RequestForUnlocked;
            default:
                return requestedState as RequestedState;
        }
    }

    /**
     * Filters a list of Financial Roles such that only FTE roles are listed, and cleared roles are listed if requested, otherwise not listed.
     */
    public filterFinancialRoles(financialRoles: IFinancialRoles[], showClearedRoles: boolean, showSubconRoles: boolean = false): IFinancialRoles[] {
        let roleValues: IFinancialRoles[];

        if (showSubconRoles) {
            roleValues = financialRoles.filter((r) => r.isFte || this.subConRolePartNumbers.indexOf(r.rolePartNumber) > -1);
        } else {
            roleValues = financialRoles.filter((r) => r.isFte);
        }

        if (!showClearedRoles) {
            roleValues = roleValues.filter((r) => r.isCleared === false);
        }

        return roleValues;
    }

    /**
     * Validates that the cost is less than 9 digits as SAP only supports a cost
     * with 9 or less digits.
     *
     * @public
     * @param {number} numberValue
     * @returns {boolean}
     * @memberof LaborRequestModalComponent
     */
    public validateCrNumberValue(numberValue: number): boolean {
        return this.getNumberOfDigits(numberValue) <= 9;
    }

    /**
     * Determines if a given project is SrOut or not based on engagement creation code
     * Currently "95", "98", "82", "83" are engagement creation codes
     * @param {string} engagementCode
     * @memberof SharedFunctionsService
     */
    public isSrOutEngagementBasedOnCreationCode(engagementCode: string): boolean {
        return SROutEngagementCreationCodes.indexOf(engagementCode) > -1;
    }

    /**
     * Determines if a given project is SrOut or not based on engagement creation code
     * Currently "Z2", is engagement creation code
     * @param {string} engagementCode
     * @memberof SharedFunctionsService
     */
    public isECIFPreSalesEngagementBasedOnCreationCode(engagementCode: string): boolean {
        return engagementCode.toLowerCase() === ECIF_EngagementCreationCode.toLowerCase();
    }

    /**
     * Determines if a given project is ECIF Presales or not based on engagement type code
     * Currently "Z00011", is engagement type code
     * @param {string} engagementCode
     * @memberof SharedFunctionsService
     */
    public isECIFPreSalesEngagementBasedOnTypeCode(engagementCode: string): boolean {
        return engagementCode.toLowerCase() === ECIF_EngagementTypeCode.toLowerCase();
    }

    /**
     * Determines if a given project is SrOut or not based on engagement type code
     * Currently "Z000010", "Z000007", "Z000008", "Z000009" are engagement type codes
     * @param {string} engagementTypeCode
     * @memberof SharedFunctionsService
     */
    public isSrOutEngagementBasedOnTypeCode(engagementTypeCode: string): boolean {
        return SROutEngagementTypeCodes.indexOf(engagementTypeCode) > -1;
    }

    /**
     * Determines if a given project is SrOut Federal support engagement type code / creation code
     * Currently "Z000010", 83  are engagement type and creation codes
     * @param {string} engagementTypeCode
     * @memberof SharedFunctionsService
     */
    public isSrOutFederalSupportEngagement(engagementTypeCode: string): boolean {
        return (engagementTypeCode === "Z000010" || engagementTypeCode === "83");
    }

    /**
     * Determines if a given project is federal SrOut or not based on engagement creation code
     * Currently "82", "83" are federal engagement creation codes
     * @param {string} engagementCode
     * @memberof SharedFunctionsService
     */
    public isFederalSrOutEngagementBasedOnCreationCode(engagementCode: string): boolean {
        return FederalSROutEngagementCreationCodes.indexOf(engagementCode) > -1;
    }

    /**
     * Copy element contents to the clipboard
     */
    public copyToClipboard(copiedElementId: string): void {
        const copiedElement = document.getElementById(copiedElementId);
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(copiedElement);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
    }

    /**
     * Check for teams context and return true/false based on that
     */
    public isTeamsAppContext(stateService: StateService): boolean {
        return stateService && stateService.params && stateService.params.isTeamsAppContext === "true" ? true : false;
    }

    /**
     * Add param isTeamsAppContext if the visited link is for teamsAppContext
     */
    public getUiParams(
        uiParam: {
            [key: string]: any;
        },
        isTeamsContext: boolean): {
            [key: string]: any;
        } {
        if (isTeamsContext) {
            const teamsAppContextParams = { isTeamsAppContext: true };
            const params = { ...uiParam, ...teamsAppContextParams };
            return params;
        }
        return uiParam;
    }

    /**
     * Check for query string source=iframe in the url and return true/false accordingly.
     */
    public isSourceIframe(): boolean {
        const urlQueryString = window.location.search;
        if (urlQueryString && urlQueryString.indexOf("source=iframe") > 0) {
            return true;
        }
        return false;
    }

    /**
     * validating to call pagination component
     *
     */
    public isPaginationRequired(list: any[], itemsPerPage: number = 5): boolean {
        if (list && list.length > itemsPerPage) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * gets the error message for logging
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public getErrorMessage(error: any, defaultMessage: string): string {
        let errorMessage: string;
        if (error.data) {
            errorMessage = error.statusText || error.data;
            if (error.data.Message || error.data.message) {
                errorMessage = error.data.Message || error.data.message;
            }
            if (error.data.ErrorMessage) {
                errorMessage = error.data.ErrorMessage;
            }
            if (Array.isArray(error.data) && error.data.length) {
                errorMessage = "";
                for (const err of error.data) {
                    errorMessage = err.error + errorMessage;
                }
            }
            if (error.data.InnerErrors || error.data.innerErrors) {
                const innerErrors = error.data.InnerErrors || error.data.innerErrors;
                for (const innerError of innerErrors) {
                    if (innerError.Messages || innerError.messages) {
                        const innerErrorMessage = innerError.Messages || innerError.messages;
                        errorMessage = "";
                        for (const message of innerErrorMessage) {
                            errorMessage = message + ". " + errorMessage;
                        }
                    }
                }
            }
        }
        if (!errorMessage) {
            errorMessage = defaultMessage;
        }
        return errorMessage;
    }

    /**
      * Converts the given date object into an ngbDateStruct, including
      * offsetting the month by 1 due to 0 vs 1 indexing in the object.
      * @param date
      */
    public convertDateToNgbDateStruct(date: Date): NgbDateStruct {
        const momentDate = moment(date);
        if (date) {
            return {
                year: momentDate.year(),
                month: momentDate.month() + 1,
                day: momentDate.date(),
            };
        }
        return undefined;
    }

    /**
      * Removes attribute from an element
      */
    public removeAttribute(name: string, elementSelector: string, attributeName: string, index?: number): void {
        if (elementSelector === "class") {
            const elements = document.getElementsByClassName(name);
            if (elements && elements.length) {
                elements[index].removeAttribute(attributeName);
            }
        } else if (elementSelector === "id") {
            const element = document.getElementById(name);
            if (element) {
                element.removeAttribute(attributeName);
            }
        }
    }

    /**
     * Gets Domains from the "Dimension str" field
     *
     * @param {IDimensionStr} dimensionStr
     * @returns {IDomainDetails[]}
     * @memberof OneProfileService
     */
    public getDomainDetailsFromDimensions(dimensionStr: IDimensionStr[]): IDomainDetails[] {
        const domainDetails: IDomainDetails[] = [];
        for (const iterator of dimensionStr) {
            if (domainDetails.filter((d) => d.DomainId === iterator.DomainId).length === 0) {
                domainDetails.push({
                    DomainId: iterator.DomainId,
                    DomainName: iterator.DomainName
                });
            }
        }
        return domainDetails;
    }

    /**
     * Gets Domains from the skills field
     *
     * @param {IMasterSkillsLevels} skillDetail
     * @returns {IDomainDetails[]}
     * @memberof OneProfileService
     */
    public getDomainFromSkill(skillDetail: IMasterSkillsLevels | ISkillDetails): IDomainDetails[] {
        const domainDetails: IDomainDetails[] = [];
        domainDetails.push({
            DomainId: skillDetail.DomainId,
            DomainName: skillDetail.DomainName
        });
        return domainDetails;
    }

    /**
    * Function that checks the userStatusCode and returns the Rec Rev value for a project.
    * @param contractType conatins the project contract type
    * @param userStatusCode contains the user status code 
    * @returns recRevType for a project
    Business assumption:
    1. Different Funding types: OIC, OIN, PFF & DIR
    2.	Different Fee Types: TNM, FIF & RAR
        i.	Irrespective of fee type if funding type is OIC or OIN or PFF then Rec Rev Type should be “n/a”,
        ii.	Irrespective of fee type if funding type is DIR then Rec Rev Type Should be “POCC”,
    3.	ContractType T&M would have only one fee type: TNM
    4.	ContractType FF could have two fee types: FIF or RAR

    Logic:
    •	For fee type FIF if (i) or (ii) are not true then the rev type value would be POCC
    •	For Fee Type RAR if (i) or (ii) are not true then the rev type value would be Ratable
    */
    public getRecRevType(contractType: string, userStatusCode: string, projectTypeCode: string): string {
        const userStatusCodeUpperCase = userStatusCode ? userStatusCode.toLocaleUpperCase() : null;
        if (userStatusCodeUpperCase && userStatusCodeUpperCase.includes("DIR")) {
            return "POCC";
        }
        if (contractType && contractType.toLocaleUpperCase() === "FF") {           

            if (userStatusCodeUpperCase && userStatusCodeUpperCase.includes("FIF")) {
                
                if (userStatusCodeUpperCase.includes("OIC")
                    || userStatusCodeUpperCase.includes("OIN")
                    || userStatusCodeUpperCase.includes("PFF")) {
                    return "n/a";
                }
                if (RatableProjectTypeCodes.indexOf(projectTypeCode) > -1 &&
                    !userStatusCodeUpperCase.includes("DIR"))
                {
                    return "Ratable";
                }

                return "POCC";
            } else if (
                userStatusCodeUpperCase && userStatusCodeUpperCase.includes("RAR")
            ) {
                if (userStatusCodeUpperCase.includes("DIR")) {
                    return "POCC";
                }
                else if (userStatusCodeUpperCase.includes("OIC")
                    || userStatusCodeUpperCase.includes("OIN")
                    || userStatusCodeUpperCase.includes("PFF")) {
                    return "n/a";
                }

                return "Ratable";
            }

            return "n/a";
        }
        else if (contractType && contractType.toLocaleUpperCase() === "T&M") {           
            
            if (userStatusCodeUpperCase && userStatusCodeUpperCase.includes("DIR")) {
                return "POCC";
            }
            else if (userStatusCodeUpperCase && (userStatusCodeUpperCase.includes("OIC")
                || userStatusCodeUpperCase.includes("OIN")
                || userStatusCodeUpperCase.includes("PFF"))) {
                return "n/a";
            }
            if (RatableProjectTypeCodes.indexOf(projectTypeCode) > -1)
            {
                return "Ratable";
            }

            return "T&M";
        }

        return contractType;
    }

    /**
     * Function that returns true if Ratable Icon needs to be shown 
     * @param projects array that represents the projects/pacakages in an engagement.
     */
    public showRatableIconForEngagement(projects: IProjectDetailsV2[]): boolean {
        if (projects && projects.length) {
            return projects.some(
                (p) =>
                    this.getRecRevType(p.contractType, p.userStatusCode, p.projectTypeCode) === "Ratable"
            );
        }
        return false;
    }

    /**
     * Get aliases list of DMM.
     *
     * @param {IEngagementDetailsV2} engagementData
     * @returns {string[]}
     * @memberof SharedFunctionsService
     */
    public getListofDMM(engagementData: IEngagementDetailsV2): string[] {
        let listOfDMM: string[] = [];
        if (!engagementData) {
            return listOfDMM;
        }

        // Get dmmPPjm for engagement
        if (engagementData.dmmPPjm && engagementData.dmmPPjm.alias) {
            listOfDMM.push(engagementData.dmmPPjm.alias);
        }

        // Get additional ppjms for projects under engagement

        for (const project of engagementData.projects) {
            if (project && project.dmmPjm && project.dmmPjm.alias) {
                listOfDMM.push(project.dmmPjm.alias);
            }
        }
        listOfDMM = this.getArrayWithoutDupes(listOfDMM);
        return listOfDMM;
    }

    public getCurrentEntityContext(): string {
        if (window && window.location && window.location.href) {
            if (window.location.href.toLocaleUpperCase().includes(EntityType.Project.toLocaleUpperCase())) {
                return EntityType.Project;
            }
        }

        return EntityType.Engagement;
    }

    public getCurrentEntityId(): string {
        if (window && window.location && window.location.href) {
            if (window.location.href.toLocaleUpperCase().includes(EntityType.Project.toLocaleUpperCase())) {
                const URL_ARRAY = window.location.href.split("/");
                let index = 0;
                for (const current of URL_ARRAY) {
                    index++;
                    if (current.toLocaleUpperCase() === EntityType.Project.toLocaleUpperCase()) {
                        break;
                    }
                }

                return URL_ARRAY[index];
            }
            else if (window.location.href.toLocaleUpperCase().includes(EntityType.Engagement.toLocaleUpperCase())) {
                const URL_ARRAY = window.location.href.split("/");
                let index = 0;
                for (const current of URL_ARRAY) {
                    index++;
                    if (current.toLocaleUpperCase() === EntityType.Engagement.toLocaleUpperCase()) {
                        break;
                    }
                }

                return URL_ARRAY[index];
            }
        }
    }

    /**
     * Returns the type of entity based on its ID.
     *
     * @param {string} wbsId
     * @return {*}  {EntityType | undefined}
     * @memberof SharedFunctionsService
     */
    public getEntityType(wbsId: string): EntityType | undefined {
        const engagementRegex = /^[a-zA-Z]{1}\.[0-9]{10}$/;
        const projectRegex = /^[a-zA-Z]{1}\.[0-9]{10}\.[0-9]{6}$/;
        const taskRegex = /^[a-zA-Z]{1}\.[0-9]{10}\.[0-9]{6}\.[0-9]{2}$/;
        const resourceRegex = /^[0-9]{5}$/;

        if (wbsId.match(engagementRegex)) {
            return EntityType.Engagement;
        } else if (wbsId.match(projectRegex)) {
            return EntityType.Project;
        } else if (wbsId.match(taskRegex)) {
            return EntityType.Task;
        } else if (wbsId.match(resourceRegex)) {
            return EntityType.Resource;
        } else {
            return undefined;
        }
    }

}
