import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://LMT-AFD-ENDPOINT-UAT-fdd8eehqh6bsfbgk.b02.azurefd.net',
  serviceEndPoints: [
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/Time2-Dev',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/lmt-draftlabor-uat',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/UxApi-UAT',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://tm-web-core-dev-eastus.azurewebsites.net/',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://servicesuat.microsoft.com/api/v1/Search/',
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/projectmanagementuat/api/v2.0',
    },
    {
      clientId: 'f9f20a9f-d0d3-4633-ba06-c211afb45bb4',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/o2c/projectmanagementuat/api/v1.0',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/time-core/api/v1',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/time-core/api/v1',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://tm-web-ux-uat-eastus.azurewebsites.net/',
    },
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net/',
    },
    {
      clientId: 'https://msxuat.crm.dynamics.com',
      serviceEndPoint: 'https://msxuat.crm.dynamics.com/api/data',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/ati-coreapi-uat',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/rmavl',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint: 'https://professionalservicesint.microsoft.com/us-coreapi-uat',
    },
  ],
};
