import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { FxpRootScopeService } from '../../../js/services/FxpRootScopeService';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appLeftNavOutsideClick]'
})
export class LeftNavOutsideClickDirective implements OnDestroy {
  @Input() appLeftNavOutsideClick: boolean; // To control the directive activation
  private fxpRootScope: any;
  private subscription: Subscription;
  private rootScopeService: FxpRootScopeService;

  constructor(private elementRef: ElementRef) {
    // Subscribe to rootScope changes
    this.rootScopeService = FxpRootScopeService.getInstance();
    this.subscription = this.rootScopeService.rootScopeSubject.subscribe(data => {
      this.fxpRootScope = data;
    });
  }

  // Listen for document clicks
  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this.appLeftNavOutsideClick) {
      return; // Exit if directive is inactive
    }

    // Check if click is inside or outside the left nav
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    const targetElement = event.target as HTMLElement;
    const isHamburgerClick = targetElement.closest('#hamburger');
    if (targetElement.closest('.leftBar')) {
      return;
    }
    // Hide tooltips if present
    const tooltips = document.querySelectorAll('.expandedtooltip');
    tooltips.forEach(tooltip => tooltip.classList.add('hidden')); // Assuming 'hidden' hides the tooltip

    // If the click is outside the left nav and the hamburger was not clicked, collapse the left nav
    if (!clickedInside && !isHamburgerClick) {
      if (this.fxpRootScope.isLeftNavOpen && !this.fxpRootScope.isLeftNavPinned) {
        this.rootScopeService.setRootScopeField('isLeftNavOpen', false);
      }
    }
    
    // If the hamburger menu is clicked, prevent collapsing
    if (isHamburgerClick) {
      this.rootScopeService.setRootScopeField('isLeftNavOpen', true);
    }
  }

  ngOnDestroy(): void {
    // Clean up the subscription when the directive is destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
