<section class="staffing-command-bar-component clearfix">
    <section class="staffing-command-bar" id="staffingCommandBar">
        <section class="content-wrapper clearfix high-contrast-command-border">
            <div class="content-wrapper--icon-div" *ngIf="isInternalEngagement">
                <a class="content-wrapper--button dm-link" type="button" tabindex="0"
                    (click)="loadAddRolePopup()">
                    <i class="icon icon-AddLegacy"></i>Add Role
                </a>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('SplitRole')">
                <button class="content-wrapper--button" tabindex="0" (click)="splitPlannedPopup()">
                    <i class="icon grphic-icons-font-split"></i>Split Role
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('RemoveRole')">
                <button class="content-wrapper--button" (click)="removeRole()" tabindex="0">
                    <span class="glyphicon glyphicon-minus-sign"></span>Remove Role
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('Copy') && !isInternalEngagement">
                <button class="content-wrapper--button" (click)="copyRequest()" tabindex="0">
                    <span class="icon icon-full icon-copy"></span>Copy
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('SuspendRole')">
                <button class="content-wrapper--button" tabindex="0" (click)="loadSuspendPopup()">
                    <i class="icon icon-InPrivate"></i>Suspend
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('UnsuspendRole')">
                <button class="content-wrapper--button" tabindex="0" (click)="loadUnsuspendPopup()">
                    <i class="icon grphic-icons-font-unsuspend"></i>Unsuspend Role
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('RoleMaintainance')">
                <button class="content-wrapper--button" tabindex="0"
                    (click)="LoadRquestRoleMaintenancePopup()">
                    <i class="icon icon-Repair"></i>Request Role Maintenance
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="isBulkMaintenanceEnabled">
                <button class="content-wrapper--button" tabindex="0"
                    (click)="navigateToBulkMaintenance()">
                    <i class="icon icon-openNewWindow"></i>Request Bulk Maintenance
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('Cancel')">
                <button class="content-wrapper--button" tabindex="0" (click)="loadCancelPopup()">
                    <i class="icon icon-cancelN"></i>Cancel
                </button>
            </div>
            <div class="content-wrapper--icon-div" *ngIf="commandBarActionsContains('TruncateRole')">
                <button class="content-wrapper--button" tabindex="0" (click)="truncatePopup()">
                    <i class="icon icon-pictureTruncate"></i>Truncate
                </button>
            </div>
            <div class="content-wrapper--icon-div">
                <a href="https://aka.ms/pjm-job-aid/staffing-role-maintenence" target="_blank" (click)="logLearnMore()"
                    class="content-wrapper--button dm-link" role="link"
                    title="Learn more about Staffing Maintenance actions" aria-label="Learn more link, Learn more about Staffing Maintenance actions, open link in new tab">
                    <i class="icon icon-education"></i>Learn more</a>
            </div>

            <section class="filter-wrapper clearfix">
                <section class="input__cmdbarsearch dm-tile--no-pad" role="search" aria-label="Search expanded tasks" title="Search Demands/Resources">
                    <input class="searchBox input__text" type="text" list="cmdbarSearchList"
                        id="cmdbarSearch" [(ngModel)]="cmdbarSearch" name="cmdbarSearch"
                        placeholder="Search" [attr.aria-label]="(cmdbarSearch && isStaffingSearchResultsFound.length) ? (cmdbarSearch + 'Search Results Found') : 'Search expanded tasks'"
                        (keyup.enter)="searchTasks()">
                    <button class="button-container icon-wrapper-search" id="cmdbar-search" type="button"
                        [attr.aria-label]="(cmdbarSearch && isStaffingSearchResultsFound.length) ? (cmdbarSearch + 'Search Results Found') : 'search demands or resources'" (click)="searchTasks()">
                        <i class="icon dm-icon-search"></i>
                    </button>
                    <!-- (change)="onInvoiceNumberChange()" -->
                    <datalist id="cmdbarSearchList">
                        <!-- API integration needed to be done for V2 -->
                        <!-- <option *ngFor="let cmdbar of cmdbarSearchList" [value]="cmdbar.cmdbarNumber"
                            [attr.aria-label]="cmdbar.cmdbarNumber" role="listbox" aria-live="polite" aria-relevant="all"
                            aria-atomic="true">
                            {{cmdbar.cmdbarNumber}}
                        </option> -->
                    </datalist>
                </section>
                <!-- <dm-tooltip role="tooltip" class="dm-tooltipinline amedmentsTooltip-mobile"
                        [dmPlacement]="'bottom'" [ngbTooltipContent]="'Search in the format: columnName: searchText'"
                        [dmIcon]="'tile__icon--info icon icon-info'">
                    </dm-tooltip> -->
                <section class="cmdbarfilter font-caption-alt">
                    <div class="d-inline-block" *ngIf="false" ngbDropdown #filterDropdown="ngbDropdown"
                        placement="bottom-right">
                        <section id="customFilter">
                            <button class="btn font-caption-alt" ngbDropdownToggle>
                                <i class="icon icon-filter"></i> Filter
                            </button>
                        </section>
                        <section ngbDropdownMenu aria-labelledby="customFilter">
                            <section class="p-b-8 font-caption-alt">
                                <label class="input__label--text" for="quick-search">
                                    Resource Request Status
                                </label>
                                <section class="multiselect-section">
                                    <ng-multiselect-dropdown hidden="true" tabindex="0" [placeholder]="''"
                                        [data]="statusList" [(ngModel)]="statusSelected" #selectStatus="ngModel"
                                        class="font-caption-alt" id="status-multiselect-dropdown"
                                        name="status-multiselect-dropdown" [settings]="dropdownSettings"
                                        (onSelect)="setSelectedStatusModel(statusSelected)"
                                        (onDeSelect)="setSelectedStatusModel(statusSelected)"
                                        (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll()" required>
                                    </ng-multiselect-dropdown>
                                    <span class="font-caption-alt status-name-display">{{multiSelectStatusModel}}</span>
                                </section>
                            </section>
                            <section class="section__wrapper">
                                <section class="input__label">
                                    <label class="input__label--text" for="dm-gr-tasks-ddl"
                                        [ngClass]="{'disabled-text' :  isResetFilterLinkVisible}">
                                        Start Date<span aria-hidden="true">:</span>
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <p class="input-group">
                                            <dm-datepicker [modelDate]="filterStartDate"
                                                (onDateChange)="onStartDateChange($event)"
                                                ariaLabelButton="Start date input field" ariaLabelCalendar="Start date"
                                                previosFousingElementId="status-multiselect-dropdown">
                                            </dm-datepicker>
                                        </p>
                                    </section>
                                </section>
                            </section>
                            <section class="section__wrapper">
                                <section class="input__label">
                                    <label class="input__label--text" for="dm-gr-tasks-ddl"
                                        [ngClass]="{'disabled-text' : isResetFilterLinkVisible}">
                                        End Date<span aria-hidden="true">:</span>
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <p class="input-group">
                                            <dm-datepicker [modelDate]="filterEndDate"
                                                (onDateChange)="onEndDateChange($event)"
                                                ariaLabelButton="End date input field" ariaLabelCalendar="End date"
                                                previosFousingElementId="calenderBtn_Start date">
                                            </dm-datepicker>
                                        </p>
                                    </section>
                                </section>
                            </section>
                            <section>
                                <button class="fxp-btn fxp-btn--primary" (click)="applyFilter(filterDropdown);">
                                    Apply filters
                                </button>
                            </section>
                        </section>
                    </div>
                </section>
            </section>
        </section>
    </section>
</section>