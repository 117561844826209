<div aria-live="assertive">
    <div role="note" attr.aria-label="Left Navigation {{ isLeftNavOpen ? 'Expanded' : 'Collapsed' }}"></div>
</div>
<!-- Navigation (Hidden in small screens i.e. mobile) -->
<div id="fxp-sidebar" class="col-xs-12 leftBar" *ngIf="!isLeftNavOpen && !isMobile()">
    <div class="sidebar-menu row" *ngIf="leftNavData.internalLinks && leftNavData.internalLinks.length > 0">
        <div>
            <ul>
                <li tabindex="-1" class="left-nav-menu btn-group" (keydown)="leftNavKeydown($event)"
                    (click)="expandLeftNav()">
                    <a class="icon-container" id="hamburger" role="button" attr.aria-label="click to expand menu">
                        <i class="icon icon-hamburger"></i>
                        <span role="tooltip" class="font-caption displayText">Menu</span>
                    </a>
                </li>
            </ul>
            <ul role="menu">
                <li *ngFor="let item of leftNavData.internalLinks | slice:0:visibleInternalLinksCount; trackBy: trackById"
                    tabindex="-1" role="menuitem" class="left-nav-menu btn-group">
                    <a id="Fxpdashboard_LeftNavItem_{{item.id}}" class="icon-container"
                        [ngClass]="{'fxp-active': item.id === selectedLeftNavItemLinkId}" [fxpLeftNavLink]="item"
                        [isLeftNavItem]="true" tabindex="0"
                        attr.aria-label="{{ item.id === selectedLeftNavItemLinkId ? ('selected ' + item.displayName + ' link') : (item.displayName + ' link') }}"
                        (keydown)="leftNavKeydown($event)" (click)="openFlyoutOnClick(item, $event)"
                        title="{{ item.tooltip ? item.tooltip : item.displayName }}">
                        <i class="{{item.iconCSS}}"></i>
                        <span role="tooltip" class="font-caption displayText">{{ item.tooltip ? item.tooltip :
                            item.displayName }}</span>
                    </a>
                </li>
                <li
                    *ngIf="leftNavData.externalLinks && leftNavData.settings && (leftNavData.externalLinks.length > 0 || leftNavData.settings.length > 0)">
                    <hr role="separator" class="separator-line" aria-hidden="true">
                </li>
                <li *ngFor="let item of leftNavData.externalLinks | slice:0:visibleExternalLinksCount; trackBy: trackById"
                    tabindex="-1" role="menuitem" class="left-nav-menu btn-group" (keydown)="leftNavKeydown($event)"
                    (click)="openFlyoutOnClick(item)">
                    <a id="Fxpdashboard_LeftNavItem_{{item.id}}" class="icon-container"
                        [ngClass]="{'fxp-active': item.id === selectedLeftNavItemLinkId}" tabindex="0"
                        attr.aria-label="{{ item.id === selectedLeftNavItemLinkId ? ('selected ' + item.displayName + ' link') : (item.displayName + ' link') }}"
                        title="{{ item.tooltip ? item.tooltip : item.displayName }}">
                        <i class="{{item.iconCSS}}"></i>
                        <span role="tooltip" class="font-caption displayText">{{ item.tooltip ? item.tooltip :
                            item.displayName }}</span>
                    </a>
                </li>
                <li *ngFor="let item of leftNavData.settings | slice:0:visibleSettingLinksCount; trackBy: trackById"
                    tabindex="-1" role="menuitem" class="left-nav-menu btn-group" (keydown)="leftNavKeydown($event)"
                    (click)="openFlyoutOnClick(item)">
                    <a id="Fxpdashboard_LeftNavItem_{{item.id}}" class="icon-container"
                        [ngClass]="{'fxp-active': item.id === selectedLeftNavItemLinkId}" tabindex="0"
                        attr.aria-label="{{ item.id === selectedLeftNavItemLinkId ? ('selected ' + item.displayName + ' link') : (item.displayName + ' link') }}"
                        title="{{ item.tooltip ? item.tooltip : item.displayName }}">
                        <i class="{{item.iconCSS}}"></i>
                        <span role="tooltip" class="font-caption displayText">{{ item.tooltip ? item.tooltip :
                            item.displayName }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div *ngIf="isMoreButtonVisible">
            <ul>
                <li tabindex="-1" id="more" class="left-nav-menu btn-group" (keydown)="leftNavKeydown($event)"
                    (click)="expandLeftNav()">
                    <a class="icon-container" role="button" [ngClass]="{'fxp-active': isMoreLinkActive}" tabindex="0"
                        attr.aria-label="{{ isMoreLinkActive ? 'selected More' : 'More' }}">
                        <i class="icon icon-moreLegacy"></i>
                        <span role="tooltip" class="font-caption displayText">More</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<nav *ngIf="isLeftNavOpen" class="leftBar" [appLeftNavOutsideClick]="true" attr.aria-expanded="{{isLeftNavOpen}}"
    (ngInit)="resetLeftNavFocus()">
    <div #leftNav class="fxp-left-nav-l1-menu" [ngClass]="{'leftNavPinned' : isLeftNavPinned }">
        <div class="fxpLeftNavOpen">
            <div tabindex="-1" class="font-body hamburger left-nav-menu-item" *ngIf="!isMobile()">
                <button id="hamburger" role="button" (click)="collapseLeftNav()" class="menu"
                    (keydown)="collapseLeftNav($event)" title="Menu" attr.aria-label="click to collapse menu">
                    <i class="icon icon-hamburger"></i>
                </button>
                <button id="pin" attr.aria-label="Left Navigation {{ !isLeftNavPinned ? 'Pin' : 'Unpin'}}" class="pin"
                    [disabled]="isPageTourInProgress" (click)="onPinFlyoutClick()"
                    (keydown)="onPinFlyoutKeyDown($event)"
                    title="{{ !isLeftNavPinned ? 'Click to Pin' : 'Click to Unpin'}}">
                    <i class="icon"
                        [ngClass]="{'icon-pinLegacy': !isLeftNavPinned, 'icon-unpinLegacy': isLeftNavPinned}"></i>
                </button>
            </div>
            <div>
                <div *ngFor="let item of leftNavData.internalLinks; trackBy: trackById">
                    <div tabindex="-1" class="left-nav-menu-item panelClass"
                        style="margin-top: 7px; margin-bottom: -5px;" (click)="headerMenuChange($event, item)">
                        <a class="font-body left-nav-l1-menu-title" id="Fxpdashboard_LeftNavItem_{{item.id}}"
                            [ngClass]="{'fxp-active': item.id === selectedLeftNavItemLinkId  && !item.hasChildren}"
                            [fxpLeftNavLink]="item" [isLeftNavItem]="true"
                            [attr.aria-label]="item.id === selectedLeftNavItemLinkId && !item.hasChildren ? ('selected ' + item.displayName + ' link') : (item.displayName + ' link')"
                            title="{{item.tooltip ? item.tooltip : item.displayName}}">
                            <i class="menus {{item.iconCSS}}"></i>
                            <div id="Fxpdashboard_LeftNavItem_ParentItem_{{item.id}}" class="menu-title leftnavElement"
                                style="text-align: start;">
                                {{item.displayName}}
                            </div>
                            <span class="font-caption expandedtooltip displayText">
                                {{ item.tooltip ? item.tooltip : item.displayName }}
                            </span>
                            <i *ngIf="item.hasChildren" class="chevron icon"
                                [ngClass]="{'icon-chevronUpMed': item.isOpen, 'icon-chevronDownMed': !item.isOpen}"
                                aria-hidden="true" [attr.aria-expanded]="item.isOpen ? 'true' : 'false'"
                                (click)="toggleChildren($event, item)"></i>
                            <div style="clear:both"></div>
                        </a>
                    </div>
                    <div *ngIf="item.isOpen" class="panel-content">
                        <div
                            *ngIf="item.hasChildren && (item.isOpen || this.selectedLeftNavItemLinkId === item.id || this.selectedLeftNavItemLinkId === item.id)">
                            <div role="menu" tabindex="-1" *ngFor="let innerItem of item.children"
                                class="left-nav-menu-item" title="{{innerItem.displayName}}"
                                style="margin-top: 7px; margin-bottom: -5px;">
                                <a role="menuitem" id="Fxpdashboard_LeftNavItem_{{innerItem.id}}"
                                    class="font-caption-alt left-nav-l1-menu-item"
                                    (click)="leftNavItemClick(innerItem, $event)" [fxpLeftNavLink]="innerItem"
                                    [isLeftNavItem]="true"
                                    [ngClass]="{'fxp-active': innerItem.id === selectedLeftNavItemId}" tabindex="-1"
                                    title="{{ innerItem.tooltip ? innerItem.tooltip : innerItem.displayName }}"
                                    [attr.aria-label]="innerItem.id === selectedLeftNavItemId? ('selected ' + innerItem.displayName + ' link') : (innerItem.displayName + ' link')">
                                    <span id="Fxpdashboard_LeftNavItem_ChildItem_{{innerItem.id}}"
                                        class="menu-child leftnavElement">{{innerItem.displayName}}</span>
                                    <span class="font-caption expandedtooltip displayText">
                                        {{ innerItem.tooltip ? innerItem.tooltip : innerItem.displayName }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr role="separator" *ngIf="leftNavData.externalLinks && leftNavData.externalLinks.length > 0"
                    class="separator" aria-hidden="true">
                <!-- external links -->
                <ngb-accordion closeOthers="true"
                    *ngIf="leftNavData.externalLinks && leftNavData.externalLinks.length > 0">
                    <ngb-panel *ngFor="let item of leftNavData.externalLinks; trackBy: trackById">
                        <ng-template ngbPanelTitle>
                            <div tabindex="-1" class="left-nav-menu-item panelClass"
                                (click)="headerMenuChange($event, item)">
                                <a class="font-body left-nav-l1-menu-title" id="Fxpdashboard_LeftNavItem_{{item.id}}"
                                    [ngClass]="{'fxp-active': item.id === selectedLeftNavItemLinkId && !item.hasChildren}"
                                    [attr.aria-label]="item.id === selectedLeftNavItemLinkId && !item.hasChildren? ('selected ' + item.displayName + ' link') : (item.displayName + ' link')"
                                    [title]="item.tooltip ? item.tooltip : item.displayName">
                                    <i class="menus {{item.iconCSS}}"></i>
                                    <div id="Fxpdashboard_LeftNavItem_ParentItem_{{item.id}}"
                                        class="menu-title leftnavElement" style="text-align: start;">
                                        {{item.displayName}}
                                    </div>
                                    <span class="font-caption expandedtooltip displayText">
                                        {{ item.tooltip ? item.tooltip : item.displayName }}
                                    </span>
                                    <i *ngIf="item.hasChildren" class="chevron icon"
                                        [ngClass]="{'icon-chevronUpMed': item.isOpen, 'icon-chevronDownMed': !item.isOpen}"
                                        aria-hidden="true" [attr.aria-expanded]="item.isOpen"></i>
                                    <div style="clear:both"></div>
                                </a>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent *ngIf="item.hasChildren || item.isOpen">
                            <div role="menu" tabindex="-1" *ngFor="let innerItem of item.children"
                                class="left-nav-menu-item" title="{{innerItem.displayName}}">
                                <a role="menuitem" id="Fxpdashboard_LeftNavItem_{{innerItem.id}}"
                                    class="font-caption-alt left-nav-l1-menu-item"
                                    (click)="leftNavItemClick(innerItem, $event)" [fxpLeftNavLink]="innerItem"
                                    [ngClass]="{'fxp-active': innerItem.id === selectedLeftNavItemId}" tabindex="-1"
                                    [title]="innerItem.tooltip ? innerItem.tooltip : innerItem.displayName"
                                    [attr.aria-label]="innerItem.id === selectedLeftNavItemId ? ('selected ' + innerItem.displayName + ' link') : (innerItem.displayName + ' link')">
                                    <span id="Fxpdashboard_LeftNavItem_ChildItem_{{innerItem.id}}"
                                        class="menu-child leftnavElement">{{innerItem.displayName}}</span>
                                    <span class="font-caption expandedtooltip displayText">
                                        {{ innerItem.tooltip ? innerItem.tooltip : innerItem.displayName }}
                                    </span>
                                </a>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <hr role="separator" *ngIf="leftNavData.settings && leftNavData.settings.length > 0" class="separator"
                    aria-hidden="true">

                <!-- settings -->
                <ngb-accordion closeOthers="true" *ngIf="leftNavData.settings && leftNavData.settings.length > 0">
                    <ngb-panel *ngFor="let item of leftNavData.settings; trackBy: trackById">
                        <ng-template ngbPanelTitle>
                            <div tabindex="-1" class="left-nav-menu-item panelClass"
                                (click)="headerMenuChange($event, item)">
                                <a class="font-body left-nav-l1-menu-title" id="Fxpdashboard_LeftNavItem_{{item.id}}"
                                    [ngClass]="{'fxp-active': item.id === selectedLeftNavItemLinkId && !item.hasChildren}"
                                    [attr.aria-label]="item.id === selectedLeftNavItemLinkId && !item.hasChildren ? ('selected ' + item.displayName + ' link') : (item.displayName + ' link')"
                                    [title]="item.tooltip ? item.tooltip : item.displayName">
                                    <i class="menus {{item.iconCSS}}"></i>
                                    <div id="Fxpdashboard_LeftNavItem_ParentItem_{{item.id}}"
                                        class="menu-title leftnavElement" style="text-align: start;">
                                        {{item.displayName}}
                                    </div>
                                    <span class="font-caption expandedtooltip displayText">
                                        {{ item.tooltip ? item.tooltip : item.displayName }}
                                    </span>
                                    <i *ngIf="item.hasChildren" class="chevron icon"
                                        [ngClass]="{'icon-chevronUpMed': item.isOpen, 'icon-chevronDownMed': !item.isOpen}"
                                        aria-hidden="true" [attr.aria-expanded]="item.isOpen"></i>
                                    <div style="clear:both"></div>
                                </a>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent *ngIf="item.hasChildren || item.isOpen">
                            <div role="menu" tabindex="-1" *ngFor="let innerItem of item.children"
                                class="left-nav-menu-item" title="{{innerItem.displayName}}">
                                <a role="menuitem" id="Fxpdashboard_LeftNavItem_{{innerItem.id}}"
                                    class="font-caption-alt left-nav-l1-menu-item"
                                    (click)="leftNavItemClick(innerItem, $event)" [fxpLeftNavLink]="innerItem"
                                    [ngClass]="{'fxp-active': innerItem.id === selectedLeftNavItemId}" tabindex="-1"
                                    [title]="innerItem.tooltip ? innerItem.tooltip : innerItem.displayName"
                                    [attr.aria-label]="innerItem.id === selectedLeftNavItemId ? ('selected ' + innerItem.displayName + ' link') : (innerItem.displayName + ' link')">
                                    <span id="Fxpdashboard_LeftNavItem_ChildItem_{{innerItem.id}}"
                                        class="menu-child leftnavElement">{{innerItem.displayName}}</span>
                                    <span class="font-caption expandedtooltip displayText">
                                        {{ innerItem.tooltip ? innerItem.tooltip : innerItem.displayName }}
                                    </span>
                                </a>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</nav>