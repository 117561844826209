import { IIrisAppSettings } from '../interface/IIrisAppSettings';

export const prod: IIrisAppSettings = {
  cdnBaseUrl: 'https://iris-fd-cpc8d6c9buafasc2.b01.azurefd.net/iris-ux',
  serviceEndPoints: [
    {
      clientId: '3a269c1e-2a2d-4f5d-922a-24179d2b1aaa',
      serviceEndPoint: 'https://iris-api-fd.azurefd.net/api/',
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/',
    }
  ],
  IrisAppConfiguration: {
    'irisApiBaseUrl': 'https://iris-api-fd.azurefd.net/api/',
    'graphApiUrl': 'https://graph.microsoft.com/v1.0',
    'dashboardUrl': '/#/opdashboard',
    'enableLookAhead': 'true'
  }
};
