import { Component, Input, OnInit } from '@angular/core';
import { FxpConfigurationService } from '../../../js/services/FxpConfiguration';
import { FxpConstants } from '../../../js/common/ApplicationConstants';
import { TelemetryConstants } from '../../../js/telemetry/TelemetryConst';
import { FxpLoggerService } from '../../../js/telemetry/fxpLogger';

@Component({
    selector: 'fxpfooter',
    templateUrl: './fxpfooter.component.html'
})
export class FxpFooterComponent implements OnInit {
    @Input() footerdata: any = {};

    private readonly sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FooterController`;

    constructor(
        private fxpConfigurationService: FxpConfigurationService,
        private fxpLoggerService: FxpLoggerService
    ) { }

    ngOnInit(): void {
        this.footerdata = this.fxpConfigurationService.FxpBaseConfiguration.FxpFooterData;
        let buildNumber = this.fxpConfigurationService.FxpAppSettings.BuildNumber ?? window["FxpAppSettings"]["BuildNumber"];
        this.footerdata.RightAlignedLinks.forEach((element: any) => {
            if (element.ExpressionName && element.ExpressionName === "BuildNumber") {
                element.DisplayText = `Build ${buildNumber}`;
            }
        });
        this.updateCopyRightDataWithCurrentYear();
        // this.adjustFooterHeight();

    }

    private adjustFooterHeight(): void {
        const headerHeight = (document.querySelector("#header") as HTMLElement).offsetHeight;
        const footerHeight = (document.querySelector("#fxpfooter") as HTMLElement).offsetHeight;
        const windowHeight = window.innerHeight;
        const partnerApp = document.querySelector(".partner-app") as HTMLElement;

        partnerApp.style.minHeight = `${windowHeight - headerHeight - footerHeight}px`;
    }

    logFooterUsageTelemetryInfo(footerItem: any): void {
        var propBag = this.fxpLoggerService.createPropertyBag();
        propBag.addToBag(FxpConstants.metricConstants.FooterLinkUrl, footerItem.href);
        propBag.addToBag(FxpConstants.metricConstants.FooterLinkName, footerItem.DisplayText);
        this.fxpLoggerService.logUserAction(this.sourceForTelemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FooterLinkClick`, "Footer link clicked by user", propBag);
    }

    private updateCopyRightDataWithCurrentYear(): void {
        let copyRightData = this.footerdata.LeftAlignedLinks.find(data => { return data.TabIndex == '401' });
        let currentYear = new Date().getFullYear();
        copyRightData.DisplayText = `©${currentYear} ${copyRightData.DisplayText} `;
    }

    evaluateExpression(expressionName: string): any {
        if (typeof this[expressionName] === 'function') {
            return this[expressionName]();
        }
        return null;
    }
}
