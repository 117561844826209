import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonComponentsModule } from "../../../../../entry/common-components.module";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { EcifIOConsumedModalComponent } from "./ecif-io-consumed-modal.component";
import { EcifIOEndDateExtensionModalModule } from "./io-enddate-extension-modal.module";
import { FormsModule } from "@angular/forms";
import { DmModalV2Module } from "../../../../../../components/modals/dm-modal-v2/dm-modal-v2.module";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        EcifIOConsumedModalComponent,
    ],
    imports: [
        CommonComponentsModule, // Contains tooltip, typeahead, custom pipes, and other custom components used throughout the app
        CommonModule,
        EcifIOEndDateExtensionModalModule,
        FormsModule,
        NgbModule,
        DmModalV2Module,
        NgbDropdownModule
    ],
    providers: [
        DMLoggerService,
        SharedFunctionsService
    ],
    entryComponents: [
        EcifIOConsumedModalComponent,
    ],
    exports: [
        EcifIOConsumedModalComponent,
    ]
})
export class EcifIOConsumedModalModule { }
