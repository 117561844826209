import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';
export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://premier-fde-uat-aygnfaafdpgba4cr.b01.azurefd.net/premier-fxp',
  apiBaseUrl: 'https://premier-fde-uat-aygnfaafdpgba4cr.b01.azurefd.net/',
  apimUrl: 'https://professionalservicesint.microsoft.com/agreementmanagementuat/',
  apimSub: 'e39f11cbac144b01b3c527f817db7756',
  compassOneBaseUrl: 'https://c1uat.microsoft.com',
  mdmBaseUrl: 'https://servicesuat.microsoft.com/#/contracts',
  environment: 'uat',
  serviceEndPoints: [
    {
      clientId: '136b9b86-4fa2-476a-9ea5-ffbdbe4f87bc',
      serviceEndPoint: 'https://premier-fde-uat-aygnfaafdpgba4cr.b01.azurefd.net/api',
    },
  ]
};
