import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ComponentType, SystemEvent } from '@microsoftit/telemetry-extensions-npm';
import * as Q from 'q';
import { ApplicationConstants, FxpConstants } from '../../../js/common/ApplicationConstants';
import { SettingsType } from '../../../js/common/SettingsType';
import { ErrorCodes } from '../../../js/constants/errorCodes';
import { FxpContext } from '../../../js/context/FxpContext';
import { SettingsServiceProvider } from '../../../js/provider/SettingsServiceProvider';
import { Resiliency } from '../../../js/resiliency/FxpResiliency';
import { FxpEventBroadCastService } from '../../../js/services/BroadCastingService';
import { DashboardService } from '../../../js/services/dashboardService';
import { FxpBroadcastedEvents } from '../../../js/services/FxpBroadcastedEvents';
import { FxpConfigurationService } from '../../../js/services/FxpConfiguration';
import { FxpRootScopeService, IRootSubjects } from '../../../js/services/FxpRootScopeService';
import { FxpRouteService } from '../../../js/services/FxpRouteService';
import { UserClaimsService } from '../../../js/services/UserClaimsService';
import { UserInfoService } from '../../../js/services/UserInfoService';
import { UserProfileService } from '../../../js/services/userProfileService';
import { ErrorSeverityLevel } from '../../../js/telemetry/ErrorSeverityLevel';
import { FxpLoggerService } from '../../../js/telemetry/fxpLogger';
import { TelemetryConstants } from '../../../js/telemetry/TelemetryConst';
import { TelemetryContext } from '../../../js/telemetry/telemetrycontext';
import { CommonUtils } from '../../../js/utils/CommonUtils';
import { DeviceFactoryProvider } from '../../../js/utils/devicefactoryService';
import { AdminLandingService } from '../../adminTiles/AdminLandingService';
import { FxpMessageService } from '../../banner/FxpMessageService';
import { FxpStateService } from '../../services/FxpStateRoutingHelperService';

interface ILeftNavData {
    internalLinks: any[];
    externalLinks: any[];
    settings: any[];
}

@Component({
    selector: 'fxpleftnavigation',
    templateUrl: './fxpleftnavigation.component.html'
})
export class FxpLeftNavigationComponent implements OnInit, OnDestroy {
    @Input() isLeftNavOpen: boolean = false;
    @Input() isLeftNavExpanded: boolean = true;
    @Input() isLeftNavPinned: boolean = true;
    @Input() leftNavData: ILeftNavData = {
        internalLinks: [],
        externalLinks: [],
        settings: []
    };
    @Input() visibleInternalLinksCount: number = 5;;
    @Input() visibleExternalLinksCount: number = 5;
    @Input() visibleSettingLinksCount: number = 5;
    @Input() isMoreButtonVisible: boolean = false;
    @Input() isMoreLinkActive: boolean = false;
    @ViewChild('leftNav', { static: false }) leftNav!: ElementRef;
    private fxpRootScopeService: FxpRootScopeService;
    private fxpRootScope: IRootSubjects;
    private fxpTelemetryContext: TelemetryContext;
    private CurrentSelectedLeftNavItem = "CurrentSelectedLeftNavItem";
    private currentUserDefaultState = "";
    private sourceForTelemetry: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftNavController`;

    selectedLeftNavItemLinkId: number = -1;
    selectedLeftNavItemId: number;
    selectedLeftNavItemSequence: number;
    loggedInUserLeftNavData: ILeftNavData;
    oboUserLeftNavData: ILeftNavData;
    isNavLinkFocused: boolean;
    leftNavDataExists: boolean;

    constructor(
        private state: FxpStateService,
        private fxpContext: FxpContext,
        private userInfoService: UserInfoService,
        private fxpEventBroadCastService: FxpEventBroadCastService,
        private fxpConfigurationService: FxpConfigurationService,
        private userClaimsService: UserClaimsService,
        private adminLandingService: AdminLandingService,
        private dashboardService: DashboardService,
        private fxpRouteService: FxpRouteService,
        private userProfileService: UserProfileService,
        private fxpMessage: FxpMessageService,
        private device: DeviceFactoryProvider,
        private fxpLoggerService: FxpLoggerService,
        private settingsService: SettingsServiceProvider,
        private renderer: Renderer2
        
    ) { }

    ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeWindow);
    }

    ngOnInit(): void {
        this.leftNavData = {
            internalLinks: [],
            externalLinks: [],
            settings: []
        };
        this.loggedInUserLeftNavData = {
            internalLinks: [],
            externalLinks: [],
            settings: []
        };
        this.oboUserLeftNavData = {
            internalLinks: [],
            externalLinks: [],
            settings: []
        };

        this.fxpRootScopeService = FxpRootScopeService.getInstance();
        this.fxpRootScopeService.rootScopeSubject.subscribe((rootScope: IRootSubjects) => {
            this.fxpRootScope = rootScope;
            this.isLeftNavOpen = rootScope.isLeftNavOpen;
            this.isLeftNavPinned = rootScope.isLeftNavPinned;
        });
        this.fxpRootScopeService.on(FxpBroadcastedEvents.OnLeftNavHighlightByStateName, (stateName) => {
            if (this.leftNavData && this.leftNavData.internalLinks && stateName) {
                this.filterGLNItemForHighlight(this.leftNavData.internalLinks, stateName);
            }
        });
        this.fxpTelemetryContext = TelemetryContext.getInstance();
        this.isMoreLinkActive = false;
        this.selectedLeftNavItemLinkId = -1;
        this.isNavLinkFocused = false;
        var userAlias = this.userInfoService.getCurrentUser();
        this.leftNavDataExists = false;
        this.getLeftNavigationData(userAlias);
        this.fxpRootScopeService.on(FxpBroadcastedEvents.OnLeftNavToggleExpandedState, (event) => {
            this.isLeftNavOpen = this.isLeftNavOpen == event[0].isLeftNavExpanded ? !event[0].isLeftNavExpanded : event[0].isLeftNavExpanded;
            this.isLeftNavExpanded = this.isLeftNavExpanded == event[0].isLeftNavExpanded ? !event[0].isLeftNavExpanded : event[0].isLeftNavExpanded;
            if (!this.isLeftNavOpen) {
                this.collapseLeftNavEventHandler(event[0]);
            }
            else {
                this.expandLeftNavEventHandler();
            }
        });

        this.setLoggedInUserPreferences();

        this.fxpRootScopeService.on(FxpBroadcastedEvents.OnUserContextChanged, (currentUserChangedUserAlias) => {
            let currentUserAlias = currentUserChangedUserAlias?.length > 0 ? currentUserChangedUserAlias[0] : null;
            // on close and open AOB , hightlight the default landing experience page                
            this.fxpRootScopeService.setRootScopeField('fxpBreadcrumb', new Array());
            this.fxpContext.deleteContext(this.CurrentSelectedLeftNavItem, ApplicationConstants.FxPDbName).then((data) => {
                console.log(this.CurrentSelectedLeftNavItem + "- data deleted" + data);
            });
            if (!this.userInfoService.isActingOnBehalfOf() && this.loggedInUserLeftNavData) {
                this.onSuccessGetLeftNav(this.loggedInUserLeftNavData, currentUserAlias);
            } else {
                this.leftNavData = {
                    internalLinks: [],
                    externalLinks: [],
                    settings: []
                };
                this.leftNavDataExists = false;
                this.getLeftNav(window["tenantConfiguration"].TenantId, currentUserAlias);
            }
            if (!this.userInfoService.isActingOnBehalfOf())
                this.setLoggedInUserPreferences();
        });



        ////event to handle window resize
        window.addEventListener('resize', this.resizeWindow);
    }

    /**
   * A method to get the Logged-in User preferences
   * @method Fxp.Controllers.LeftNavController.setLoggedInUserPreferences
   * @example <caption> Example to use setLoggedInUserPreferences </caption>
   * LeftNavController.setLoggedInUserPreferences ()
   */
    setLoggedInUserPreferences(): void {
        let self = this;
        self.fxpContext.readContext(ApplicationConstants.UserPreferencesStorageKey.replace('{0}', self.userInfoService.getLoggedInUser()),
            ApplicationConstants.FxPDbName).then(function (context) {
                var settings = (context && context.Result) ? JSON.parse(context.Result) : {};
                self.fxpRootScopeService.setRootScopeField("isLeftNavOpen", (settings.isLeftNavPinned === undefined) ? true : settings.isLeftNavPinned);
                self.fxpRootScopeService.setRootScopeField('isLeftNavPinned', (settings.isLeftNavPinned === undefined) ? true : settings.isLeftNavPinned);
                self.resizePartnetApp();
            });
    }

    collapseLeftNavEventHandler = ($event) => {
        if (this.isLeftNavOpen || this.isLeftNavExpanded || !$event || $event.keyCode == FxpConstants.keyCodes.escapeKey || $event.keyCode == FxpConstants.keyCodes.enterKey || $event.keyCode == FxpConstants.keyCodes.spaceBar) {
            this.fxpRootScopeService.setRootScopeField("isLeftNavOpen", false);
            this.setFocusToHamburger();
        }
    }


    resizeWindow = () => {
        setTimeout(() => {
            this.isNavLinkFocused = false;
            this.resizeLeftNavHeight();
            if (this.loggedInUserLeftNavData && !this.userInfoService.isActingOnBehalfOf()) {
                this.onSuccessGetLeftNav(this.loggedInUserLeftNavData, this.userInfoService.getLoggedInUser());
            } else if (this.oboUserLeftNavData && this.userInfoService.isActingOnBehalfOf()) {
                this.onSuccessGetLeftNav(this.oboUserLeftNavData, this.userInfoService.getCurrentUser());
            }
        }, 0);
    }

    getLeftNavigationData(userAlias) {
        const self = this;
        let tenantId = window["tenantConfiguration"].TenantId;
        let noRoleAppRoleName = 'norole';
        if (self.fxpConfigurationService.FxpAppSettings.NoRole_RoleName) {
            noRoleAppRoleName = self.fxpConfigurationService.FxpAppSettings.NoRole_RoleName.toString().toLowerCase();
        }

        if (tenantId === "ES") {
            this.userClaimsService.getUserConsolidatedRoles(userAlias)
                .then(function (roles: Array<string>) {
                    if (roles.findIndex(e => e.toLowerCase() === noRoleAppRoleName) >= 0) {
                        tenantId = 'ES_NoRole';
                    }
                    self.getLeftNav(tenantId, userAlias);
                });
        }
        else {
            self.getLeftNav(tenantId, userAlias);
        }
    }

    /**  
       * A method to get the LeftNav data for the user
       * @method Fxp.Controllers.LeftNavController.getLeftNav
       * @example <caption> Example to use getLeftNav</caption>
       * LeftNavController.getLeftNav('tenantId','userAlias')
       */
    getLeftNav(tenantId: string, userAlias: string) {
        const source = `${this.sourceForTelemetry}.GetLeftNav`;
        let self = this;
        let cachedResponse = "";
        setTimeout(() => {
            this.adminLandingService.GetAdminTileDetails(true);
        }, 2000);

        var deferred = Q.defer();
        self.userProfileService.getCurrentUserFlighitngContext().then((flightingContext) => {
            let encodedBasicProfileContext = window.btoa(JSON.stringify(flightingContext)).toString();
            self.dashboardService.getLeftNavData(tenantId, encodedBasicProfileContext).then((response) => {
                if (JSON.stringify(response.data) !== cachedResponse) {
                    this.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.LeftNavigation.FetchLefNavData', 'API Response of GetLeftNavData is different from Cached Data', 'getLeftNav');
                    this.onSuccessGetLeftNav(response.data != undefined ? response.data : [], userAlias);
                }
                deferred.resolve(response);
            }, (error) => {
                var propbag = self.fxpLoggerService.createPropertyBag();
                propbag.addToBag(FxpConstants.metricConstants.RequestedUserAlias, userAlias);
                propbag.addToBag(FxpConstants.metricConstants.Status, error.status);
                propbag.addToBag(FxpConstants.metricConstants.StatusText, error.statusText);
                propbag.addToBag('RedBannerDisplayed', 'Yes');
                self.fxpMessage.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.LeftNavServiceCallFailedError.ErrorMessage, FxpConstants.messageType.error);
                self.fxpLoggerService.logError(source,
                    self.fxpRootScope.fxpUIConstants.UIMessages.LeftNavServiceCallFailedError.ErrorMessageTitle,
                    ErrorCodes.GetLeftNavData_Failure,
                    CommonUtils.objectToString(error),
                    propbag,
                    undefined,
                    undefined,
                    ErrorSeverityLevel.High);
                deferred.reject(error);
            }, (cachedData) => {
                this.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + '.LeftNavigation.LeftNavDataFromCache', 'Returning the previous cached results of GetLeftNavData API call.', 'getLeftNav');
                cachedResponse = JSON.stringify(cachedData);
                this.onSuccessGetLeftNav(cachedData != undefined ? cachedData : [], userAlias);
            }
            );

        });
        return deferred.promise;
    }

    logSystemEvent(sytemEventName: string, systemMessage: string, methodName: string) {
        let processName = 'FxpPlatform.LeftNavigation.';
        let currentProcessName = processName + sytemEventName;
        let eventData = new SystemEvent(currentProcessName, ComponentType.Web, systemMessage);
        this.fxpLoggerService.logSystemEvent(`${this.sourceForTelemetry}.${methodName}`, eventData);
    }


    /**
    * A method to update LeftNav data for the current user
    * @method Fxp.Controllers.LeftNavController.onSuccessGetLeftNav
    * @example <caption> Example to use onSuccessGetLeftNav</caption>
    * LeftNavController.onSuccessGetLeftNav(leftNavData,'alias')
    */
    onSuccessGetLeftNav = (leftNavData: ILeftNavData, userAlias: string) => {
        var self = this;
        if (leftNavData.internalLinks && leftNavData?.internalLinks?.length > 0 && leftNavData.internalLinks[0].displayName.toLowerCase() === "dashboard") {
            leftNavData.internalLinks[0].targetUIStateName = self.fxpRouteService.getDefaultStateName();
        }
        if (!this.userInfoService.isActingOnBehalfOf()) {
            this.loggedInUserLeftNavData = { ...leftNavData };
        } else {
            this.oboUserLeftNavData = { ...leftNavData };
        }

        self.userClaimsService.getUserConsolidatedRoles(userAlias)
            .then(function (roles: Array<string>) {
                if (roles.findIndex(e => e == "Admin") >= 0) {

                    if (self.fxpConfigurationService.FxpBaseConfiguration.AdminLeftNav) {
                        var LeftNavAdminData = self.fxpConfigurationService.FxpBaseConfiguration.AdminLeftNav;
                        leftNavData.internalLinks.push(LeftNavAdminData);
                    }
                }
            });
        if (self.fxpConfigurationService.FxpBaseConfiguration.RMDashboard) {
            var rmDashboard = self.fxpConfigurationService.FxpBaseConfiguration.RMDashboard;
            leftNavData.internalLinks.push(rmDashboard);
        }
        if (self.fxpConfigurationService.FxpBaseConfiguration.FxpCapabilities) {
            var fxpCapabilities = self.fxpConfigurationService.FxpBaseConfiguration.FxpCapabilities;
            leftNavData.internalLinks.push(fxpCapabilities);
        }
        if (self.fxpConfigurationService.FxpBaseConfiguration.ProfileComponent) {
            var profileComponent = self.fxpConfigurationService.FxpBaseConfiguration.ProfileComponent;
            leftNavData.internalLinks.push(profileComponent);
        }

        self.leftNavData = self.getDeviceSpecificLeftNavItems(leftNavData);

        if (leftNavData.internalLinks && leftNavData?.internalLinks?.length > 0)
            self.checkLinksWithMissingModules(leftNavData.internalLinks);

        self.leftNavDataExists = self.leftNavData.internalLinks ? self.leftNavData.internalLinks.length > 0 : false;
        self.setSequence();
        self.fxpRootScope.appLoaded = true;
        if (!self.leftNavDataExists) {
            self.fxpMessage.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.GeneralExceptionError.ErrorMessage, FxpConstants.messageType.error);
            return;
        }
        setTimeout(function () {
            self.resizeLeftNavHeight();
            self.findActiveLeftNavItem(self.leftNavData.internalLinks);
        }, 1000);
    }

    /**
    * A method to  calculate no of icons to show in ui based on window height
    * @method Fxp.Controllers.LeftNavController.resizeLeftnavHeight
    * @example <caption> Example to use resizeLeftnavHeight</caption>
    * LeftNavController.resizeLeftnavHeight()
    */
    resizeLeftNavHeight = (): void => {
        var visibleLinksCount = this.getVisibleLinksCount();
        var self = this;
        if (!CommonUtils.isNullOrEmpty(self.leftNavData)) {
            var internalLinksCount = CommonUtils.getArrayLength(self.leftNavData.internalLinks ?? []);
            var externalLinksCount = CommonUtils.getArrayLength(self.leftNavData.externalLinks ?? []);
            var settingLinksCount = CommonUtils.getArrayLength(self.leftNavData.settings ?? []);

            const linksCount = internalLinksCount + externalLinksCount + settingLinksCount;
            self.isMoreButtonVisible = visibleLinksCount < linksCount;
            self.isMoreLinkActive = self.selectedLeftNavItemSequence > visibleLinksCount;
            self.visibleInternalLinksCount = Math.min(visibleLinksCount, internalLinksCount);

            visibleLinksCount = visibleLinksCount - self.visibleInternalLinksCount;
            self.visibleExternalLinksCount = Math.min(visibleLinksCount, externalLinksCount);

            visibleLinksCount = visibleLinksCount - self.visibleExternalLinksCount;
            self.visibleSettingLinksCount = Math.min(visibleLinksCount, settingLinksCount);
        }
    }

    /**
    * A method to  use to get the VisibleLinksCount
    * @method Fxp.Controllers.LeftNavController.VisibleLinksCount
    * @example <caption> Example to use VisibleLinksCount</caption>
    * LeftNavController.getVisibleLinksCount()
    */
    getVisibleLinksCount(): number {
        let leftNavHeight = this.getLeftNavHeight();
        var menuIconHeight = document.querySelector("#hamburger")?.clientHeight;
        var noOfIconsToShow = (Math.floor(leftNavHeight / menuIconHeight) - 1);
        return noOfIconsToShow;
    }

    /**
    * A method to  use to get the leftNAvHeight
    * @method Fxp.Controllers.LeftNavController.getLeftNavHeight
    * @example <caption> Example to use getLeftNavHeight</caption>
    * LeftNavController.getLeftNavHeight()
    */
    getLeftNavHeight(): number {
        var headerHeight = document.querySelector("#header")?.clientHeight;
        var footerHeight = document.querySelector("#fxpfooter")?.clientHeight;
        var moreHeight = document.querySelector("#hamburger")?.clientHeight;
        var windowHeight = self.window.innerHeight;
        var leftNavHeight = windowHeight - headerHeight - footerHeight - moreHeight;
        return leftNavHeight;
    }



    /**
    * A method to get the leftNav items on device specific
    * @method Fxp.Controllers.LeftNavController.getDeviceSpecificLeftNavItems
    * @param {leftNavData } leftNavData leftnavdata returned by fxp service api.
    * @example <caption> Example to use getDeviceSpecificLeftNavItems</caption>
    * LeftNavController.getDeviceSpecificLeftNavItems(leftNavData)
    */
    getDeviceSpecificLeftNavItems = (leftNavData: ILeftNavData): any => {
        var self = this;
        if (self.isMobile()) {
            leftNavData.internalLinks = leftNavData.internalLinks.filter(function (item) {
                return (item.applicableDevice == FxpConstants.applicableDevice.mobile || item.applicableDevice == FxpConstants.applicableDevice.all)
            });
        }
        else {
            leftNavData.internalLinks = leftNavData.internalLinks.filter(function (item) {
                return (item.applicableDevice == FxpConstants.applicableDevice.desktop || item.applicableDevice == FxpConstants.applicableDevice.all)
            });
        }
        return leftNavData;
    }

    /**
    * A method to set Sequence no to leftNav items
    * @method Fxp.Controllers.LeftNavController.setSequence
    * @example <caption> Example to use setSequence</caption>
    * LeftNavController.setSequence()
    */
    private setSequence() {
        var self = this;
        if (self.leftNavDataExists && self.leftNavData.internalLinks) {
            self.sortObject(self.leftNavData.internalLinks, "sortOrder");
            for (var i = 0; i < self.leftNavData?.internalLinks?.length; i++) {
                self.leftNavData.internalLinks[i].sequence = i + 1;
                if (self.leftNavData.internalLinks[i].hasChildren) {
                    var children = self.leftNavData.internalLinks[i].children;
                    for (var j = 0; j < children?.length; j++) {
                        children[j].parentSequence = i + 1;
                    }
                }
            }
        }
    }

    private checkLinksWithMissingModules(links) {
        //Update Left nav data for states that are not available
        for (var l = 0; l < links?.length; l++) {
            var leftNavLink = links[l];
            if (leftNavLink.hasChildren && leftNavLink.children && leftNavLink?.children?.length > 0) {
                this.checkLinksWithMissingModules(leftNavLink.children);
            }
            else {
                if (Resiliency.statesWithMissingModules.indexOf(leftNavLink.targetUIStateName) > -1) {
                    leftNavLink.dependenciesMissing = true;

                }
            }
        }
    }

    sortObject = function (srtObject, srtElement) {
        srtObject.sort(function (a, b) {
            return a[srtElement] > b[srtElement] ? 1 : a[srtElement] < b[srtElement] ? -1 : 0;
        });
    };


    expandLeftNav(): void {
        this.fxpRootScopeService.broadcast(FxpBroadcastedEvents.OnLeftNavToggleExpandedState, { isLeftNavExpanded: true });
    }


    expandLeftNavEventHandler = () => {
        var self = this;
        self.fxpRootScopeService.setRootScopeField("isLeftNavOpen", true);
        self.setFocusToHamburger();
        self.resetLeftNavFocus();
        self.resizePartnetApp();
        this.leftNavData.internalLinks.forEach((item) => {
            item.isOpen = (item.id === self.selectedLeftNavItemLinkId);
        });
        if (this.leftNavData.externalLinks) {
            this.leftNavData.externalLinks.forEach((item) => {
                item.isOpen = false;
            });
        }

        if (this.leftNavData.settings) {
            this.leftNavData.settings.forEach((item) => {
                item.isOpen = false;
            });
        }
    }

    resizePartnetApp= function() {
        const partnerAppElement = document.querySelector('.partner-app') as HTMLElement;
        if (partnerAppElement) {
            const newWidth = this.isLeftNavPinned && this.isLeftNavOpen ? 'calc(100% - 250px)' : 'calc(100% - 44px)';
            this.renderer.setStyle(partnerAppElement, 'width', newWidth);
          }
    }

    setFocusToHamburger = function () {
        setTimeout(function () {
            const hamburger = document.querySelector('#hamburger') as HTMLElement;
            hamburger.focus();
        }, 50)
    }


    collapseLeftNav = ($event): void => {
        var self = this;
        if (!$event || $event.keyCode == FxpConstants.keyCodes.escapeKey || $event.keyCode == FxpConstants.keyCodes.enterKey || $event.keyCode == FxpConstants.keyCodes.spaceBar) {
            self.fxpEventBroadCastService.broadCast(FxpBroadcastedEvents.OnLeftNavToggleExpandedState, { isLeftNavExpanded: false });
        }
        this.resizePartnetApp();
    }

    onPinFlyoutClick = ($event) => {
        var leftNavPinUnPinActionStartTime = performance.now();
        var uiPinUnpinActionDuration;
        if (!this.fxpRootScope.isLeftNavPinned) {
            this.fxpRootScopeService.setRootScopeField('isLeftNavPinned', true);
            document.getElementById('pin').focus();
            this.fxpEventBroadCastService.broadCast(FxpBroadcastedEvents.OnLeftNavTogglePinState, { isLeftNavPinned: true });
        }
        else {
            this.fxpRootScopeService.setRootScopeField('isLeftNavPinned', false);
            this.fxpRootScopeService.setRootScopeField("isLeftNavOpen", false);
            this.fxpEventBroadCastService.broadCast(FxpBroadcastedEvents.OnLeftNavTogglePinState, { isLeftNavPinned: false });
        }
        uiPinUnpinActionDuration = performance.now() - leftNavPinUnPinActionStartTime;
        this.saveLeftNavPinSetting(uiPinUnpinActionDuration);
        this.resizePartnetApp();
    }

    /**
    * A method to save the LeftNav pin/unpin settings of the user preferences
    * @method Fxp.Controllers.LeftNavController.saveLeftNavPinSetting
    * @example <caption> Example to use getLeftNav</caption>
    * LeftNavController.saveLeftNavPinSetting()
    */
    saveLeftNavPinSetting = (uiPinUnpinActionDuration) => {
        // let settingsService = new SettingsServiceProvider();
        let self = this;
        if (!self.userInfoService.isActingOnBehalfOf()) {
            let userAlias = self.userInfoService.getLoggedInUser();
            let userPreferencesStorageKey = ApplicationConstants.UserPreferencesStorageKey.replace('{0}', userAlias);
            self.fxpContext.readContext(userPreferencesStorageKey, ApplicationConstants.FxPDbName).then(function (context) {
                let userPreferences = (context && context.Result) ? JSON.parse(context.Result) : {};
                userPreferences.isLeftNavPinned = self.fxpRootScope.isLeftNavPinned;
                let strUserPreferences = JSON.stringify(userPreferences);
                var saveSettingsAPIStartTime = performance.now();
                self.settingsService.saveSettings(SettingsType.User,
                    userAlias,
                    ApplicationConstants.UserPreferencesSettings,
                    strUserPreferences).then((response) => {
                        var saveSettingsAPIDuration = performance.now() - saveSettingsAPIStartTime;
                        self.logTelemetryForPinAction(uiPinUnpinActionDuration, saveSettingsAPIDuration);
                        self.fxpContext.saveContext(userPreferencesStorageKey, strUserPreferences, ApplicationConstants.FxPDbName);
                    }).catch((error) => {
                        let propbag = self.fxpLoggerService.createPropertyBag();
                        propbag.addToBag(FxpConstants.metricConstants.RequestedUserAlias, userAlias);
                        propbag.addToBag(FxpConstants.metricConstants.ScreenRoute, self.state.getCurrentStateName());
                        propbag.addToBag(FxpConstants.metricConstants.Geography, self.fxpTelemetryContext.getGeography());
                        propbag.addToBag(FxpConstants.metricConstants.BrowserType, self.device.isDesktop() ? 'Desktop' : 'Mobile');
                        propbag.addToBag(FxpConstants.metricConstants.Status, error.status);
                        propbag.addToBag(FxpConstants.metricConstants.StatusText, error.statusText + ' ' + error.data);
                        self.userProfileService.getBasicProfileByAlias(self.userInfoService.getLoggedInUser(), null).then((loggedInUserInfo: any) => {
                            propbag.addToBag(FxpConstants.metricConstants.UserBusinessRole, loggedInUserInfo.businessRole);
                            self.fxpLoggerService.logError(self.sourceForTelemetry,
                                self.fxpRootScope.fxpUIConstants.UIMessages.SaveSettingsServiceCallFailedError.ErrorMessageTitle,
                                ErrorCodes.SaveLeftNavPinSetting_Error,
                                null,
                                propbag,
                                null,
                                null,
                                ErrorSeverityLevel.High
                            );
                        });
                    });
            });
        }
    }

    logTelemetryForPinAction(uiPinUnpinActionDuration, saveSettingsResponseDuration) {
        var self = this;
        var leftNavPinStatus = self.fxpRootScope.isLeftNavPinned ? "LeftNavPinned" : "LeftNavUnPinned";
        var uiPinUnpinDurationKey = self.fxpRootScope.isLeftNavPinned ? "UILeftNavPinnedDuration" : "UILeftNavUnPinnedDuration";
        var propbag = self.fxpLoggerService.createPropertyBag();
        propbag.addToBag(uiPinUnpinDurationKey, uiPinUnpinActionDuration);
        propbag.addToBag(FxpConstants.metricConstants.LeftNavPinStatus, leftNavPinStatus);
        propbag.addToBag(FxpConstants.metricConstants.SaveSettingsAPIResponseDuration, saveSettingsResponseDuration);
        propbag.addToBag(FxpConstants.metricConstants.ScreenRoute, self.state.getCurrentStateName());
        propbag.addToBag(FxpConstants.metricConstants.Geography, self.fxpTelemetryContext.getGeography());
        propbag.addToBag(FxpConstants.metricConstants.BrowserType, self.device.isDesktop() ? 'Desktop' : 'Mobile');
        self.userProfileService.getBasicProfileByAlias(self.userInfoService.getLoggedInUser(), null).then(function (loggedInUserInfo) {
            propbag.addToBag(FxpConstants.metricConstants.UserBusinessRole, loggedInUserInfo["businessRole"]);
            self.fxpLoggerService.logUserAction(self.sourceForTelemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.LeftNavigationPinAction`, 'LeftNavigation Pin State Toggled', propbag);
        });
    }


    leftNavKeydown = ($event) => {
        var self = this;
        var targetMenu = $($event.target).closest("#fxp-sidebar"),
            allMenuItems = targetMenu.find("li a").filter(':visible'),
            currentMenuItemIndex = allMenuItems.index($event.target);

        switch ($event.keyCode) {
            case FxpConstants.keyCodes.arrowDownKey:
                if (currentMenuItemIndex < (allMenuItems.length - 1)) {
                    allMenuItems[currentMenuItemIndex + 1].focus();
                }
                break;
            case FxpConstants.keyCodes.arrowUpKey:
                if (currentMenuItemIndex > 0) {
                    allMenuItems[currentMenuItemIndex - 1].focus();
                }
                break;
            case FxpConstants.keyCodes.spaceBar:
                // self.expandLeftNavEvent(); // TODO: Find the correct method to call
                break;
        }
    }

    openFlyoutOnClick = (item, $event) => {
        var self = this;
        if (!$event.ctrlKey && !$event.shiftKey) {
            if (!item.parentId && item.hasChildren === false) {
                //Highlight left navigation item only if page is not dirty.
                if (!window["_showPageDirtyPopup"]) {
                    self.setSelectedLeftNavIds(item.id, -1, item.sequence);
                }
            }
            this.leftNavData.internalLinks.forEach((item) => {
                item.isOpen = false;
            });
            if (this.leftNavData.externalLinks) {
                this.leftNavData.externalLinks.forEach((item) => {
                    item.isOpen = false;
                });
            }
            if (this.leftNavData.settings) {
                this.leftNavData.settings.forEach((item) => {
                    item.isOpen = false;
                });
            }

            self.resetLeftNavFocus();
            //Highlight left navigation item only if page is not dirty.
            if (!item.hasChildren && !window["_showPageDirtyPopup"]) {
                self.fxpRootScopeService.broadcast('leftNavHighlighted', item);
                return;
            }
            self.fxpRootScopeService.setRootScopeField("isLeftNavOpen", true);
            item.isOpen = true;
            setTimeout(() => {
                document.getElementById('Fxpdashboard_LeftNavItem_' + item.id).focus();
            }, 50);
        }
    }

    resetLeftNavFocus = function () {
        setTimeout(() => {
            document.querySelector('.mCustomScrollBox')?.setAttribute("tabindex", "-1");
            document.querySelectorAll('.accordion-toggle')?.forEach(element => {
                element.setAttribute("tabindex", "-1");
            });
        }, 1000)
    }

    /**
    * click GLN item/link and Highlight it
    * @method Fxp.Controllers.LeftNavController.leftNavItemClick
    * @param {item} item item is LeftNav Item.
    * @example <caption> Example to use leftNavItemClick</caption>
    * LeftNavController.leftNavItemClick(item)
    */
    leftNavItemClick = (item, $event) => {

        if (item.dependenciesMissing && item.dependenciesMissing === true) {
            //Handle scenario when left nav click is restricted.
        }
        else {
            var self = this;
            if ($event && !$event.ctrlKey && !$event.shiftKey) {
                if (!this.fxpRootScope.isLeftNavPinned){
                    self.fxpRootScopeService.setRootScopeField("isLeftNavOpen", false);
                    this.resizePartnetApp();
                   }
                self.highlightLeftNavItem(item);
                self.saveLeftNavToContext(item);
            }
        }
    }

    saveLeftNavToContext = (item) => {
        var self = this
        //Save left navigation item to context only if page is not dirty.
        if (!window["_showPageDirtyPopup"]) {
            self.fxpContext.saveContext(self.CurrentSelectedLeftNavItem, JSON.stringify(item), ApplicationConstants.FxPDbName);
        }
    }

    isMobile() {
        return this.device.isMobile();
    }

    /**
   * A method to to hightlight GLN link on refresh, landing experience and on bookmark.
   * @method Fxp.Controllers.LeftNavController.findActiveLeftNavItem
   * @param {response } response leftNavItems
   * @example <caption> Example to use findActiveLeftNavItem</caption>
   * LeftNavController.findActiveLeftNavItem(response)
   */
    private findActiveLeftNavItem(response) {
        var self = this;
        var stateName = self.currentUserDefaultState ? self.currentUserDefaultState : self.state.getCurrentStateName();
        if (response) {
            self.filterGLNItemForHighlight(response, stateName);
        }
    }

    private filterGLNItemForHighlight(navList, stateName) {
        var self = this;
        let leftNavItems = self.dashboardService.getGLNFlatDataStructure(navList).filter(function (item) {
            return (item.targetUIStateName && item.targetUIStateName.toLowerCase() === stateName.toLowerCase());
        });
        if (leftNavItems && leftNavItems?.length >= 1) {
            self.fxpContext.readContext(self.CurrentSelectedLeftNavItem, ApplicationConstants.FxPDbName).then(function (context) {
                let clickedItem = (context && context.Result) ? JSON.parse(context.Result) : {};
                if (clickedItem) {
                    var selectedItem = leftNavItems.find(item => item.id === clickedItem.id);
                    if (selectedItem)
                        self.highlightLeftNavItem(selectedItem);
                    else
                        self.highlightLeftNavItem(leftNavItems[0]);
                } else {
                    self.highlightLeftNavItem(leftNavItems[0]);
                }

                //If parent item exist then expand it
                let parentItem = navList.find(function (item) {
                    return item.id === leftNavItems[0].parentId;
                });
                if (parentItem)
                    parentItem.isOpen = true;
            });
        }
    }

    highlightLeftNavItem = (item) => {
        var self = this;
        //Highlight left navigation item only if page is not dirty.
        if (!window["_showPageDirtyPopup"]) {
            if (item.parentId) {

                self.setSelectedLeftNavIds(item.parentId, item.id, item.parentSequence);
            }
            else {
                self.setSelectedLeftNavIds(item.id, -1, item.sequence);
            }
            self.fxpRootScopeService.broadcast('leftNavHighlighted', item);
        }
    }

    /**
    * A method to set Selected LeftNav Id's
    * @method Fxp.Controllers.LeftNavController.setSelectedLeftNavIds
    * @param {linkId } linkId linkId is Parent Id of Left Nav Item.
    * @param {id } id id is the Left Nav Item.
    * @param {sequence } sequence sequence is the Left Nav Item sequential order no of  Global Left Navigation List.
    * @example <caption> Example to use setSelectedLeftNavIds</caption>
    * LeftNavController.setSelectedLeftNavIds(linkId)
    */
    private setSelectedLeftNavIds(linkId, id, sequence) {
        var self = this;
        self.selectedLeftNavItemId = id;
        self.selectedLeftNavItemLinkId = linkId;
        self.selectedLeftNavItemSequence = sequence;
    }



    onPinFlyoutKeyDown = ($event) => {
        var self = this;
        var keyCode = ($event) ? $event.keyCode : FxpConstants.keyCodes.enterKey;
        switch (keyCode) {

            case FxpConstants.keyCodes.escapeKey:
                self.fxpEventBroadCastService.broadCast(FxpBroadcastedEvents.OnLeftNavToggleExpandedState, { isLeftNavExpanded: true });
                break;
            case FxpConstants.keyCodes.tabKey:
                if (!$event.shiftKey) {
                    var targetMenu = $($event.target).closest(".fxpLeftNavOpen"),
                        visibleMenuItems = targetMenu.find(".left-nav-menu-item a").filter(':visible');
                    setTimeout(() => {
                        if (visibleMenuItems[0])
                            visibleMenuItems[0].focus();
                    }, 50);
                }
                break;
        }
    }

    onOutsideClick = ($event) => {
        // TODO: Implement the logic to close the flyout on outside click

    }


    headerMenuChange = ($event, item) => {
        var self = this;
        // Close all other items
        if (item.hasChildren) {
            this.leftNavData.internalLinks.forEach((navItem) => {
                if (navItem !== item) {
                    navItem.isOpen = false;
                }
            });
            // Toggle the isOpen state of the clicked item
            item.isOpen = !item.isOpen;
        }
        if (!item.parentId && item.hasChildren === false) {
            //Highlight left navigation item only if page is not dirty.
            if (!window["_showPageDirtyPopup"]) {
                self.setSelectedLeftNavIds(item.id, -1, item.sequence);
            }
            // when L0 item is clicked hide all L0 with child items
            this.leftNavData.internalLinks.forEach((item) => {
                item.isOpen = false;
            });
        }
        if (!item.hasChildren) {
            if (!self.fxpRootScope.isLeftNavPinned) {
                self.fxpRootScopeService.setRootScopeField("isLeftNavOpen", false);
                var currentStateName = this.state.getCurrentStateName();
                if (item.targetUIStateName === currentStateName) {
                    setTimeout(function () {
                        document.getElementById('Fxpdashboard_LeftNavItem_' + item.id)?.focus();
                    }, 50);
                }
            }
            self.saveLeftNavToContext(item);
            $event.stopPropagation();
        }
    }

    onMenuItemClick = (item, innerItem) => {
        var self = this;
        if (!self.fxpRootScope.isLeftNavPinned) {
            self.fxpRootScopeService.setRootScopeField("isLeftNavOpen", false);
            var currentStateName = this.state.getCurrentStateName();
            if (innerItem.targetUIStateName === currentStateName) {
                setTimeout(() => {
                    document.getElementById('Fxpdashboard_LeftNavItem_' + item.id)?.focus();
                }, 50);
            }
        }
    }
    toggleChildren(event: Event, item: any): void {
        event.stopPropagation();
        // Close all other items
        if (item.hasChildren) {
            this.leftNavData.internalLinks.forEach((navItem) => {
                if (navItem !== item) {
                    navItem.isOpen = false;
                }
            });
            // Toggle the isOpen state of the clicked item
            item.isOpen = !item.isOpen;
        }
    }
}
