<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="tileMinHeight" [tileHeight]="'100%'" [isV2Tile]="true">
    <div class="dm-flex-horizontal-align-center" additionalActionsRight>
        <a class="dm-link dm-p-x-10" *ngIf="!isComponentLoading && isProjectContext"
            [uiSref]="RouteName.EngagementManageEBS" [uiParams]="{engagementId: selectedProjectData.engagementId}"
            (click)="logClick(LogEventConstants.NavigateToEngagementEBSPageClicked)">
            View Engagement EBS
        </a>
    </div>

    <dm-loading loader *ngIf="isComponentLoading && !isServerError" [loadingText]="loadingText"
        [showLoading]="showLoading">
    </dm-loading>
    <ng-container *ngIf="!isComponentLoading && !isServerError">
        <div>
            <div class="dm-flex-align-center dm-entity">
                <div class="dm-entity__name" id="wbsEntityName">
                    {{isProjectContext ? selectedProjectData.name : engagementData.name}}
                </div>
                <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-x-5 confidentialicon"
                    *ngIf="engagementData?.isConfidential || selectedProjectData?.isConfidentialDeal"
                    [dmPlacement]="'right'" [ngbTooltipContent]="confidentialText"
                    [dmIcon]="'tile__icon--info confidentialkey'" ariaLabelToolTip="confidentiality information"
                    (click)="logClick(LogEventConstants.ConfidentialTooltip)">
                </dm-tooltip>
                <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec dm-m-x-5"
                    *ngIf="(engagementData?.isUsPubSec && isPubSecEnabled && engagementData?.isPublicSector) || (selectedProjectData?.isUsPubSec && isPubSecEnabled && selectedProjectData?.isPublicSector)"
                    [dmPlacement]="'right'" [ngbTooltipContent]="pubSecText" [dmIcon]="'pubsec_icon'"
                    ariaLabelToolTip="pub sec Information" (click)="logClick(LogEventConstants.PubsecTooltip)">
                </dm-tooltip>
                <span *ngIf="engagementData?.isUsPubSec || selectedProjectData?.isUsPubSec"
                    class="uspubsec dm-m-x-5 dm-m-t-4" title="US Pubsec"></span>
                <span
                    *ngIf="engagementData?.hasUnitBasedDemands || selectedProjectData?.hasUnitBasedDemands"
                    class="units-icon dm-m-x-5 dm-m-t-4" title="Units"></span>
                <span *ngIf="!isProjectContext && showRatableIcon && areMXDRChangesEnabled"
                    class="ratable-icon dm-m-r-4 dm-m-t-4" title="Ratable"></span>
                <span *ngIf="selectedProjectData?.isMarkedForDeletion"
                    class="icon-full icon-page-remove icon-marked-for-deletion dm-m-l-4 dm-m-x-5"
                    title="This Project has been marked for deletion in CompassOne"></span>
                <span class="icon icon-full icon-copy dm-m-l-4 cursor-pointer" (click)="copyEntityName()"
                    title="Copy to clipboard"></span>
            </div>

            <div class="dm-entity__id">
                {{isProjectContext ? selectedProjectData.projectId : engagementData.id}}
            </div>

            <div class="ebs-container">
                <div class="details-container">
                    <p class="dm-entity__description">
                        {{isProjectContext ? selectedProjectData.description :
                        engagementData.description}}
                    </p>
                    <div class="dm-p-y-imp-10">
                        <dm-summary-progress-bar [progressBarData]="engagementData.ebsStateProgress"
                            *ngIf="!isProjectContext">
                        </dm-summary-progress-bar>
                        <dm-summary-progress-bar [progressBarData]="selectedProjectData.ebsStateProgress"
                            *ngIf="isProjectContext">
                        </dm-summary-progress-bar>
                    </div>
                    <div class="dm-p-y-imp-10 dm-detail">
                        <span class="dm-detail__label">Customer</span>
                        <span class="dm-detail__value">{{isProjectContext ? selectedProjectData.customerName :
                            engagementData.customerName}}</span>
                    </div>
                </div>
                <div class="image-container">
                    <img src="https://PJMUIPROD-dme6d6bhhvcpdvf5.b01.azurefd.net/dmvnext/assets/eng-summary.svg"
                        alt="Entity Details Image" />
                </div>
            </div>

            <div class="dm-m-t-20">
                <dm-date-progress-bar [dateProgressBarData]="engagementDateDetails" [progressBarWidth]="'75%'"
                    *ngIf="!isProjectContext">
                </dm-date-progress-bar>
                <dm-date-progress-bar [dateProgressBarData]="projectDateDetails" [progressBarWidth]="'75%'"
                    *ngIf="isProjectContext">
                </dm-date-progress-bar>
            </div>
        </div>
    </ng-container>
    <ng-container tileFooter *ngIf="!isComponentLoading && !isServerError">
        <button type="button" class="dm-btn" (click)="openEditEngagementDetailsModal()" aria-label="Edit Details"
            *ngIf="engagementData?.canEditEnagagement && !isProjectContext">
            <span class="dm-btn--icon icon icon-editLegacy"></span>
            <span class="dm-btn--text">Edit Details</span>
        </button>
        <button type="button" class="dm-btn" (click)="openEditProjectModal()" aria-label="Edit Details"
            *ngIf="projectData?.canEditProject && isProjectContext">
            <span class="dm-btn--icon icon icon-editLegacy"></span>
            <span class="dm-btn--text">Edit Details</span>
        </button>
        <button type="button" class="dm-btn dm-m-imp-0" (click)="openStatusChangeModal()"
            aria-label="Request Status Change"
            *ngIf="(!isProjectContext && engagementData?.canRequestEbsStateChange) || (isProjectContext && projectData?.canRequestEbsStateChange && projectData?.showPjmField)">
            <span class="dm-btn--icon icon-full icon-status-change"></span>
            <span class="dm-btn--text">Request Status Change</span>
        </button>
        <fxp-shared-component-control appname="SampleApplication"
            componentname="sampleapplication-decovalidatorcomponent" [componentinputs]="componentinputs"
            getcallbacks="onUpdateFromProfile" (oncallback)="onProfileComponentCallback($event)">
        </fxp-shared-component-control>
        <fxp-shared-component-control appname="SampleApplication"
            componentname="sampleapplication-recovalidatorcomponent" [componentinputs]="recoInput"
            getcallbacks="onUpdateFromProfile" (oncallback)="onProfileComponentCallback($event)">
        </fxp-shared-component-control>
    </ng-container>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>